import React, { Component } from "react";

class CareerBlocks extends Component {
  render() {
    /* team member data */

    let data = [
      {
        job: "Business Development Interns",
        tenure: "6 Months",
        perks: "Experience Certificate, LOR & Goodies",
      },
      {
        job: "Digital Marketing Interns",
        tenure: "6 Months",
        perks: "Experience Certificate, LOR & Goodies",
       
      },
    ];

    let Datalist = data.map((val, i) => {
      return (

        <div
          className="col-lg-3 col-sm-6 col-12 section-space--bottom--30  px-6"
        >
          <div className="team bg-white  rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
            <div className=" ">
              <img className="mx-auto pt-4" src={`assets/img/logo/logo.png`} alt={val.job} />
            </div>
            <div className="content">
              <h3 className="text-red">{val.job}</h3>
              <h4 className='text-blue-500 pt-4 font-bold'>{val.tenure}</h4>
              <div className="pt-4 font-bold">
                <span className="text-extrabold">Perks:</span>
                {val.perks}</div>
              
                <a target="_blank" href="https://forms.gle/ZWGgNj26hSvJWKXX6" className="text-blue-500 pt-4 font-extrabold underline">
                 Apply Now
                </a>
            

            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area mt-12">
          <div className="container">

            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default CareerBlocks;
