import React, { Component } from "react";
import SwiperSlider, { SwiperSlide } from "./swiper";
import { EffectFade } from "swiper";

class TestimonialSlider extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      autoplay: false,
      effect: "fade",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      modules: [EffectFade]
    };
    let data = [
      {
        testimonialImage: "12.png",
        testimonialName: "MR. PRAFULLA PATHAK",
        testimonialDesignation: "PRESIDENT - SESI",
        testimonialText:
        "Greetings, fellow renewable energy enthusiasts! As Secretary General of SESI, I am excited to witness the incredible strides we are making in the field of renewable energy. SESI serves as a hub of knowledge and expertise, connecting professionals, researchers, and industry leaders across India. By becoming a member, you will gain access to valuable resources, engaging events, and a network of passionate individuals who share the common goal of advancing renewable energy utilization."
      },
      {
        testimonialImage: "5.png",
        testimonialName: "MR. ARUN KUMAR MISHRA",
        testimonialDesignation: "SECRETARY GENERAL - SESI",
        testimonialText:
        "Namaste! As Secretary General of SESI, I am thrilled to be part of this vibrant community. Renewable energy holds immense potential in India, and it is our collective responsibility to harness this power for a sustainable future. SESI provides a platform for individuals from diverse backgrounds to collaborate, share knowledge, and contribute to the development and utilization of renewable energy. Join us as we embark on this exciting journey, working towards a greener and cleaner India for generations to come."
      },
      {
        testimonialImage: "13.png",
        testimonialName: "MR. RAJEEV KRISHNA DESHPANDE",
        testimonialDesignation: "TREASURER - SESI",
        testimonialText:
        "Greetings to all renewable energy enthusiasts and stakeholders! As the President of SESI, I am honored to lead this remarkable society dedicated to driving the renewable energy movement in India. Together, we have the power to transform our nation's energy landscape and create a sustainable future. I invite you to join us in our endeavors, as we collaborate, innovate, and make a positive impact on the world through renewable energy utilization. Let us work hand in hand towards a greener and brighter India!"
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <SwiperSlide key={i}>
          <div className="testimonial-slider__single-slide">
            <div className="author">
              <div className="author__image">
                <img
                  src={`assets/img/team/${val.testimonialImage}`}
                  alt=""
                />
              </div>
              <div className="author__details">
                <h4 className="name">{val.testimonialName}</h4>
                <div className="designation">{val.testimonialDesignation}</div>
              </div>
            </div>
            <div className="content">{val.testimonialText}</div>
          </div>
        </SwiperSlide>
      );
    });

    return (
      <div>
        {/*====================  testimonial slider area ====================*/}
        <div
          className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--120"
          style={{
            backgroundImage: `url(assets/img/about/1.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="testimonial-slider">
                  <div className="testimonial-slider__container-area">
                    <SwiperSlider options={params}>{DataList}</SwiperSlider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of testimonial slider area  ====================*/}
      </div>
    );
  }
}

export default TestimonialSlider;
