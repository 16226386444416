// import { useEffect, useState } from "react";
// import FormUI from "../../components/formsUI/FormUI";
// import Form1Foreign from "./Form1Foreign";
// import Form1Indian from "./Form1Indian";
// import { NavLink } from "react-router-dom";
// import NavBar from "../../components/NavBar";

// const RegForm1 = (props) => {
//   const [indian, setIndian] = useState(true);
//   const [member, setMember] = useState({
//     nationality: "indian",
//     salutation: "mr",
//   });
//   const [fieldEmpty, setFieldEmpty] = useState(true);
//   const formChangeHandler = (e) => {
//     e.preventDefault();
//     setMember({ ...member, [e.target.name]: e.target.value });
//   };

//   useEffect(() => {
//     if (!member.address || !member.confirmPass || !member.country || !member.address || !member.designation || !member.email || !member.mobileNumber || !member.name || !member.organization || !member.password || !member.regFee || !member.state) {
//       setFieldEmpty(true);
//     }
//     else {
//       setFieldEmpty(false)
//     }
//   }, [member]);
//   useEffect(() => {
//     localStorage.removeItem("member");
//   }, []);

//   return (
//     <div>
//       <NavBar />
//       <FormUI title="Membership Form">
//         <div>
//           <input
//             type="radio"
//             value="indian"
//             onChange={formChangeHandler}
//             name="nationality"
//             checked={indian}
//             onClick={() => setIndian(true)}
//           />
//           <span className="ml-4">Indian Nationals</span> <br />
//           <input
//             type="radio"
//             value="foreign"
//             onChange={formChangeHandler}
//             name="nationality"
//             checked={!indian}
//             onClick={() => setIndian(false)}
//           />
//           <span className="ml-4">Foreign Nationals</span>
//         </div>
//         <hr /> <br />
//         {indian && <Form1Indian onChange={formChangeHandler} />}
//         {!indian && <Form1Foreign onChange={formChangeHandler} />}
//         <div className="mt-6">
//           {/* <button onClick={() => props.proceedClickHandler(member)} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-indigo-600">
//                         Proceed
//                     </button> */}

//           {/* 1 */}

//           <button
//             disabled={!fieldEmpty ? false : true}
//             onClick={(e) => {
//               e.preventDefault();
//               props.payClickHandler(member);
//             }}
//             target="_blank"
//             className="text-center w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-indigo-600"
//           >
//             <div className={!fieldEmpty ? "" : "cursor-not-allowed"}>
//               Pay Now
//             </div>
//           </button>
//         </div>
//         <p className="mt-8 text-xs font-light text-center text-gray-700">
//           {" "}
//           Already a Member?{" "}
//           <NavLink
//             to="/login"
//             className="font-medium text-blue-500 hover:underline"
//           >
//             Login
//           </NavLink>
//         </p>
//       </FormUI>
//     </div>
//   );
// };

// export default RegForm1;

import { useEffect, useState } from "react";
import FormUI from "../../components/formsUI/FormUI";
import Form1Foreign from "./Form1Foreign";
import Form1Indian from "./Form1Indian";
import { NavLink } from "react-router-dom";
import NavBar from "../../components/NavBar";

const RegForm1 = (props) => {
  return (
    <div>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center mt-12">
              <h2 className="section-title  ">
                Registration
              </h2>
              <p className="mt-2">
              We are upgrading our systems and shifting our payment gateway provider, till then kindly fill the below form.
              </p>
            </div>
          </div>
          <div className="col-lg-12 mt-6 text-center">
          <a target="_blank" href={'https://forms.gle/mTFR8GDjKV5C6zjVA'} className="ht-btn ht-btn--round">
            Register Now</a>

            </div>
        </div>
      </div>
    </div>
  );
};

export default RegForm1;
