import { Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Modal } from "antd";

const EditModal = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(props.user);
  const [loading, setLoading] = useState(false);
  // console.log("User is : ",user);

  const handleCancel = () => {
    props.onCancel();
  };

  const onChangeHandler = (event) => {
    event.preventDefault();
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOk = async () => {
    setLoading(true);
        fetch(`https://api.sesi.in/user/editUser/${user._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(user)
        })
        .then((result)=>{
          setLoading(false)
          // props.onOk(user, user._id);
          props.onCancel();
          // navigate("/profile")
          window.location.reload();
        })
        
  };

  return (
    <Modal
      title="Edit Details"
      open={props.isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        // initialValues={{
        //   remember: true,
        // }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
            ["name"]: user.name,
            ["email"]: user.email,
            ["mobile"]: user.mobile,
            ["city"]: user.city,
            ["state"]: user.state,
            ["address"]: user.address,
            ["membership1"]: user.membership1,
            ["membership2"]: user.membership2,
            ["membership3"]: user.membership3,
            ["membership4"]: user.membership4,
            ["affiliation1"]: user.affiliation1,
            ["affiliation2"]: user.affiliation2,
            ["affiliation3"]: user.affiliation3,
            ["research1"]: user.research1,
            ["research2"]: user.research2,
            ["research3"]: user.research3,
            ["awd1"]: user.awd1,
            ["awd2"]: user.awd2,
            ["awd3"]: user.awd3,
            ["awd4"]: user.awd4,
          }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="name"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Email */}

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.email}
            name="email"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Phone */}

        <Form.Item
          label="Mobile"
          name="mobile"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.mobile}
            name="mobile"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* City */}

        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.city}
            name="city"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* State */}

        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.state}
            name="state"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Address */}

        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.address}
            name="address"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Membership */}

        <Form.Item
          label="membership1"
          name="membership1"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="membership1"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="membership2"
          name="membership2"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="membership2"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="membership3"
          name="membership3"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="membership3"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="membership4"
          name="membership4"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="membership4"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Reasearch */}

        <Form.Item
          label="research1"
          name="research1"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="research1"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="research2"
          name="research2"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="research2"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="research3"
          name="research3"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="research3"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Award */}

        <Form.Item
          label="award1"
          name="award1"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="award1"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="award2"
          name="award2"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="award2"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="award3"
          name="award3"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="award3"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="award4"
          name="award4"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="award4"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        {/* Affiliation */}

        <Form.Item
          label="affiliation1"
          name="affiliation1"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="affiliation1"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="affiliation2"
          name="affiliation2"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="affiliation2"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>

        <Form.Item
          label="affiliation3"
          name="affiliation3"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{ fontWeight: "350" }}
            // defaultValue={user.name}
            name="affiliation3"
            onChange={(event) => onChangeHandler(event)}
          />
        </Form.Item>


      </Form>
    </Modal>
  );
};

export default EditModal;