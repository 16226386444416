

import React, { Component, usefaculty } from "react";
class SesiAwards extends Component {
  constructor(props) {
    super(props);
    this.faculty = {
      currentPage: 1,
      imagesPerPage: 10,
    };
  }

  handleClick = (event) => {
    this.setfaculty({
      currentPage: grantber(event.target.id),
    });
        // Scroll to the top of the page when a page grantber is clicked
        window.scrollTo(0, 0);
  };
  render() {
    /* team member data */

    let data = [
      {
        imgg: "1.png",
        award: "Green Highest Membership Award",
        txt:"Academic Institution",
      },
      {
        imgg: "2.png",
        award: "Green Student Chapter Award",
        txt:"Academic Institutional Awards for the colleges",
      },
      {
        imgg: "3.png",
        award: " Green Campus Award",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "4.png",
        award: " Green Project Award",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "5.png",
        award: "Green Model Award ",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "6.png",
        award: " Green Innovation Award",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "7.png",
        award: "Green Entrepreneurship Award ",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "8.png",
        award: "Green Publication Award ",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "9.png",
        award: "Green Consultancy Award ",
        txt:"Academic Institution Industry Corporate House",
      },
      {
        imgg: "10.png",
        award: "Green Leadership Award ",
        txt:"Winners Among Engineering Colleges, Polytechnic Colleges, Industries & Corporate Houses",
      },
    ];
    
 // Pagination logic
 const { currentPage, imagesPerPage } = this.faculty;
 const indexOfLastImage = currentPage * imagesPerPage;
 const indexOfFirstImage = indexOfLastImage - imagesPerPage;
 const currentImages = data.slice(indexOfFirstImage, indexOfLastImage);

    let Datalist = currentImages.map((val, i) => {
      return (
    
   <div
         className="col-lg-4 col-sm-6 col-12 section-space--bottom--30  px-6"
        > 
          <div className="team bg-white  rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
          <div className=" ">
              <img className="mx-auto pt-4 w-3/4" src={`assets/img/service/sesiawards/${val.imgg}`} alt="" />
            </div>
            <div className="content">
            <h4 className='text-blue-500  text-2xl font-extrabold'>{val.award}</h4>
            <span className="pt-4 font-extrabold">{val.txt}</span>
            </div>
          </div>
        
        </div>
      );
    });

    // Page grantbers for pagination
    const pagegrantbers = [];
    for (let i = 1; i <= Math.ceil(data.length / imagesPerPage); i++) {
      pagegrantbers.push(i);
    }

    const renderPagegrantbers = pagegrantbers.map((grantber) => {
      return (
        <li
          key={grantber}
          id={grantber}
          className={currentPage === grantber ? "active" : ""}
          onClick={this.handleClick}
        >
          {grantber}
        </li>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area pt-12">
          <div className="container">
            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
{/* 
            <div className="pagination-container">
              <ul className="pagination">{renderPagegrantbers}</ul>
            </div> */}
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}
export default SesiAwards;