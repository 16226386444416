import React, { Component } from "react";

class PresidentMember extends Component {
  render() {
    /* team member data */

    let data = [
      {
        profileImage: "12.png",
        profileTitle: "Mr. Prafulla \n Pathak",
        profileDesignation: "President",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
      {
        profileImage: "1.png",
        profileTitle: "Mr. Ajay Prakash Shrivastava",
        profileDesignation: "Immediate Past President",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
    ];

    let Datalist = data.map((val, i) => {
      return (

        <div
          className="col-lg-3 col-sm-6 col-12 section-space--bottom--30 "
        >
          <div className="team bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500">
            <div className="team ">
              <img src={`assets/img/team/${val.profileImage}`} alt={val.profileTitle} />
            </div>

            <div className="content">

              <h3 style={{ whiteSpace: "pre-line" }} className="title">{val.profileTitle}</h3>


              <span className='text-blue-500 font-extrabold'>{val.profileDesignation}</span>

              <div className="social">
                <a href={val.socialLinks.facebook} className="facebook">
                  <i className="fa fa-facebook" />
                </a>
                <a href={val.socialLinks.linkedin} className="linkedin">
                  <i className="fa fa-linkedin" />
                </a>
              </div>
            </div>
          </div>

        </div>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area section-space--inner--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <h2 className="section-title section-space--bottom--50">
                    Presidents
                  </h2>
                </div>
              </div>
            </div>
            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default PresidentMember;
