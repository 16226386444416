
import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import InstitutionalBlocks from '../components/InstitutionalBlocks';
import {Helmet} from "react-helmet";


class Institutional extends Component{
    render(){
        return(
            <div>
                    <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>Institutional Members of SESI - Solar Energy Society of India</title>
<meta name="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="Institutional Members of SESI - Solar Energy Society of India"/>
<meta itemprop="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Institutional Members of SESI - Solar Energy Society of India"/>
<meta property="og:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Institutional Members of SESI - Solar Energy Society of India"/>
<meta name="twitter:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>
                {/* Navigation bar */}
                <NavBar/>

                {/*====================  Chapter page content ====================*/}
                
                {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Institutional Members</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Institutional Members</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <h1 className='text-center mt-20 mb-10'>SESI Institutional Members</h1>
        <InstitutionalBlocks/>
<br />
<br />

                {/*====================  End of Chapter page content  ====================*/}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Institutional;