import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { Components } from "antd/es/date-picker/generatePicker";
import {Helmet} from "react-helmet";

class Announcements extends Component {
  render() {
    return (
      <div>
              <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>Announcements of SESI - Solar Energy Society of India</title>
<meta name="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="Announcements of SESI - Solar Energy Society of India"/>
<meta itemprop="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Announcements of SESI - Solar Energy Society of India"/>
<meta property="og:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Announcements of SESI - Solar Energy Society of India"/>
<meta name="twitter:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>
        {/* Navigation bar */}
        <NavBar />
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Announcements</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Announcements</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*====================  Announcements content ====================*/}

        <h1 className="text-center pt-6">Announcements</h1>
        <div className="flex justify-center item-center mt-4 mb-1">
          <img className="sm:w-1/2" src="assets/img/annoucements/1.jpg" />
        </div>

        <div className="container text-base">
          <p className="font-semibold text-lg pt-6">
            4th Annual Power Tech Africa 2020
          </p>
          <p>
            4th Annual Power Tech Africa 2020 on January 30–31 2020 in Nairobi,
            Kenya has been supported by SESI and it has been agreed by the
            organizer that they will give 10% discount on registration fee to
            SESI members for participating in the Conference. This
            conference-cum-exhibition focuses on the future of Renewable Energy
            in Africa with further discussions about electrification in Kenya,
            Digitization in the power sector, future of Energy Storage and
            Supply chain challenges in executing Renewable Energy projects in
            Africa. It also discusses the potential for geothermal energy Sector
            in Uganda with renewable energy’s role in Tanzania, Rwanda, Ghana &
            Kenya. It’s a platform to discuss and debate best practices which
            can be instrumental in developing the energy sector in the region.
            The focus of the program will be on the following topics:
          </p>
          <div className="container">
            <ul className="list-disc">
              <li>
                Identifying the potential of renewable sources in the
                Sub-Saharan Regions - Wind, Solar, Tidal Wave, Geothermal, Gas
              </li>
              <li>
                Implementation of Smart Grid / Off–Grid technology projects
                using renewable energy for better and reliable power
                transmission
              </li>
              <li>
                Sources of capitalizing renewable power projects &Exploring
                Foreign Direct Investment policies for better project
                implementation
              </li>
              <li>
                Hybrid Energy System : Spurting opportunities for energy market
              </li>
            </ul>
          </div>
          <br />
          <p>
            To get further details kindly send an email to
            aditi@bricsaconsulting.com or contact Ms. Aditi Jain on +91 22 4880
            3430 during office hours. <br />
            To download the conference agenda, kindly click on the below-given
            link:{" "}
            <a href="https://bricsaconsulting.com/event/4th-annual-powertech-africa/">
              https://bricsaconsulting.com/event/4th-annual-powertech-africa/
            </a>{" "}
            <br />
            Organizer Name and URL – Bricsa Consulting Pvt. Ltd. -{" "}
            <a href="http://bricsaconsulting.com/">
              {" "}
              http://bricsaconsulting.com/
            </a>
          </p>
          <div className=" mx-4 container">
            <p className="font-semibold">
              {" "}
              Guidelines for the SESI Logo and Tagline making Competition
            </p>
            <ul className=" mx-3 list-disc">
              <li>
                The logo must be original and should not violate any provision
                of Indian Copyright Act, 1957. Plagiarism would not be allowed.
              </li>
              <li>
                The logo should be very appealing and the tagline should be
                message giving.
              </li>
              <li>
                The Solar Energy society of India will give the prize of Rs.
                11,000 for Logo winner and a prize of Rs. 4000 for Tagline
                winner.
              </li>
              <li>
                Also the winner would get a chance to participate in upcoming
                Solar Energy Society of India Conference to be held at Kolkata,
                September 14 & 15 2019.
              </li>
              <li>
                All entries have to be submitted at{" "}
                <a href="sgoffice@sesi.in ">sgoffice@sesi.in </a>
              </li>
              <li>
                In case of offline posters, it has to be scanned and then sent
                across.
              </li>
              <li>
                Last date for the submission of logo and Tagline is 30th June,
                2019.
              </li>
              <li>
                Entries would be posted on our Facebook and Instagram page
                judged based on 40% likes received and 60% Judge's personal
                discretion considering various aspects such as creativity,
                artistic merit, originality, composition.
              </li>
              <li>
                The result of the competition would be out on 15th July, 2019.
              </li>
              <li>
                The decision of the Solar Energy Society of India would be final
                and no further clarifications would be made thereof.
              </li>
              <li>
                In case of any copyright issues, the participant will be
                responsible to settle it himself, th Solar energy Society will
                not be responsible.
              </li>
              <li>
                The participation fee for an individual participant will be Rs.
                200 for students, Rs. 600 for Professionals and Rs. 1000 for
                industry.
              </li>
            </ul>
            <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 mr-6">
      <img class="object-cover object-center rounded" alt="hero" src="assets/img/annoucements/2.png"/>
    </div>
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img class="object-cover object-center rounded" alt="hero" src="assets/img/annoucements/3.png"/>
    </div>
  </div>
          </div>
          <p className="font-bold test-">
            SESI Organizing One Day Conclave(23.02.2019) in Future Energy India
            2019 Expo on CHALLENGES FACED BY INDUSTRY ACADEMIA IN UTILISING
            RENEWABLE ENERGY GROWTH{" "}
            <small className="text-base">during 22-24 February 2019</small>
          </p>
        
        </div>

        {/*====================  End of Announcements page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Announcements;
