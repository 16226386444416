import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Navb = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const logoutClickHandler = () => {
    setIsLoggedIn(false);
    sessionStorage.clear()
    navigate("/");
  }

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('loggedIn');
    // console.log("is logged in : ",loggedIn )
    setIsLoggedIn(loggedIn);
  }, [])
  return (
    <div>
      {!isLoggedIn && <NavLink to="/login" className="ht-btn ht-btn--default d-inline-block">Login</NavLink>}
      {isLoggedIn && <NavLink to="/profile" className="ht-btn ht-btn--default d-inline-block">Profile</NavLink>}
      {isLoggedIn && <span onClick={logoutClickHandler} className="ml-2 cursor-pointer ht-btn ht-btn--default d-inline-block">Logout</span>}
    </div>
  )
}

export default Navb