import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navb from "./navbarHelper/Navb";

class MobileMenu extends Component {
  state = {
    active: false,
  };

  toggleMobileMenu = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  componentDidMount() {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
  }

  render() {
    return (
      <div>
        {/*=======  offcanvas mobile menu  =======*/}
        <div
          className={`offcanvas-mobile-menu ${
            this.state.active ? "active" : ""
          } `}
          id="mobile-menu-overlay"
        >
          <a
            href="#/"
            className="offcanvas-menu-close"
            id="mobile-menu-close-trigger"
            onClick={this.toggleMobileMenu}
          >
            <i className="ion-android-close" />
          </a>
          <div className="offcanvas-wrapper">
            <div className="offcanvas-inner-content">
              <nav className="offcanvas-navigation" id="offcanvas-navigation">
                <ul>
                  <li className="">
                    <Link to={`${process.env.PUBLIC_URL}/home`}>Home</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/about-us`}>About</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}`}>Gov. Council</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/president`}>
                          Presidents
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/vice-president`}>
                          Vice Presidents
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/secretary-treasurer`}
                        >
                          Secretary General & Treasurer
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/member`}>
                          Members
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/membership`}>
                      Membership
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/membership`}>
                          Membership
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/local-chapter-guidelines`}
                        >
                          Regional, State & Local Chapter Guidelines
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/student-chapter-guidelines`}
                        >
                          Student Chapter Guidelines
                        </Link>
                      </li>

                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/chapters`}>
                          SESI Chapters
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/institutional-members`}
                        >
                          Institutional Members
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/regional-chapter`}>
                          Regional Chapter
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/student-chapters`}>
                          Student Chapter
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}`}>Events</Link>
                    <ul className="sub-menu">
                      {/* <li>
                        <Link to={`${process.env.PUBLIC_URL}`}>
                          Upcoming Events
                        </Link>
                      </li> */}
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/latest-events`}>
                          Latest Events
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/past-events`}>
                          Past Events
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/news-updates`}>
                      News Updates
                    </Link>
                  </li>
                  {/* <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}`}>NEWS</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/news-updates`}>
                          News Updates
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}`}>Newsletter</Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="menu-item-has-children"> */}
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/blog`}>Blogs</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/innovation`}>
                      Innovation
                    </Link>{" "}
                  </li>

                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}`}>SESI Exchange</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/sesi-Exchange`}>
                          Exchange
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/sesi-query`}>
                          Exchange Queries
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li><Link to={`${process.env.PUBLIC_URL}/sesi-exchange`}>SESI EXCHANGE</Link> </li> */}
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/gallery`}>
                      Gallery
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/awards`}>Awards</Link>{" "}
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/careers`}>Careers</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                      Contact Us
                    </Link>{" "}
                  </li>
                  <li>
                    <Navb />
                  </li>
                </ul>
              </nav>
              <div className="offcanvas-widget-area">
                <div className="off-canvas-contact-widget">
                  <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                      <li>
                        <i className="ion-android-phone-portrait" />{" "}
                        <a href="tel:919355155876">+91 9355155876</a>
                      </li>
                      <li>
                        <i className="ion-android-mail" />{" "}
                        <a href="mailto:sg@sesi.in">sg@sesi.in</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Off Canvas Widget Social Start*/}
                <div className="off-canvas-widget-social">
                  <a
                    target="_blank"
                    href="https://m.facebook.com/groups/178012422252927/?ref=group_header&view=group"
                  >
                    <i className="zmdi zmdi-facebook" />
                  </a>
                  <a
                    target="_blank"
                    href="https://mobile.twitter.com/solarenergyind"
                  >
                    <i className="zmdi zmdi-twitter" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/solar-energy-society-of-india-sesi-/"
                  >
                    <i className="zmdi zmdi-linkedin-box" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UC6miIUEAESDHAzVJm4Tkq-g"
                  >
                    <i className="zmdi zmdi-youtube" />
                  </a>
                </div>

                {/*Off Canvas Widget Social End*/}
              </div>
            </div>
          </div>
        </div>
        {/*=======  End of offcanvas mobile menu  =======*/}
      </div>
    );
  }
}

export default MobileMenu;
