import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import Avatar from '../assets/avatar.png';
import EditModal from '../components/formsUI/EditModal';
import Footer from '../components/Footer';

const Profile = () => {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // console.log(isModalOpen)

    useEffect(() => {
        setLoading(true);
        const userId = localStorage.getItem("userId");
        fetch(`https://api.sesi.in/user/profile/${userId}`)
            .then(res => {

                return res.json();
            })
            .then(resData => {
                setUser(resData.user);
                setLoading(false);
                // console.log(resData);
            })
            .catch(err => {
                setError(true);
                setLoading(false);

                console.log(err);
            });
    }, [])

    return (
        <div>
            <NavBar />

            {loading && <h1 className='text-center'>Loading...</h1>}

            {error && <h1 className='text-center text-red-600'>Oops, Something Went Wrong!</h1>}


            {!loading && !error && <div className="w-full ml-auto md:w-full mx-2 h-64">
                <div className='ml-auto'>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 -960 960 960" width=""><path d="M480-440q-66 0-113-47t-47-113v-140q0-25 17.5-42.5T380-800q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q25 0 42.5 17.5T640-740v140q0 66-47 113t-113 47Zm0-60q42 0 71-29t29-71v-120H380v120q0 42 29 71t71 29ZM160-120v-94q0-38 19-65t49-41q67-30 128.5-45T480-380q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-252 800-214v94H160Zm60-60h520v-34q0-16-9.5-30.5T707-266q-64-31-117-42.5T480-320q-57 0-111 11.5T252-266q-14 7-23 21.5t-9 30.5v34Zm260 0Zm0-540Z"/></svg> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width=""><path d="M226-266q62-39 122.5-58T480-343q71 0 133 20t122 57q42-55 59-105.457Q811-421.914 811-480q0-140.247-95.326-235.623Q620.349-811 480.174-811 340-811 244.5-715.623 149-620.247 149-480q0 58 17.027 108.217T226-266Zm253.814-177q-60.971 0-101.893-41.186Q337-525.372 337-585.686T378.107-687.5Q419.215-729 480.186-729t101.893 41.686Q623-645.628 623-585.314T581.893-484q-41.108 41-102.079 41Zm-.219 388q-87.146 0-164.894-33.283-77.747-33.282-135.82-91.552-58.073-58.271-90.977-135.449Q55-392.462 55-480.39q0-87.929 33.5-165.269Q122-723 180-780.5 238-838 315.247-872q77.246-34 165.253-34 88.007 0 165.253 34Q723-838 780.5-780.5 838-723 872-645.594t34 165.328q0 87.922-34 165.094T780.5-180Q723-122 645.465-88.5T479.595-55Z"/></svg> */}
                    <img src={Avatar} height="150px" width="150px" style={{ margin: "auto", paddingTop: "12px" }} />
                </div>

                <div className="bg-white p-3 shadow-sm rounded-sm">
                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                        <span clas="text-green-500">
                            <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </span>
                        <span className="tracking-wide">About</span>
                    </div>
                    <div className="text-gray-700">
                        <div className="grid md:grid-cols-2 text-sm">
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Name</div>
                                <div className="px-4 py-2">{user.name}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Contact No.</div>
                                <div className="px-4 py-2">{user.mobile || "null"}</div>

                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">
                                    Salutation
                                </div>
                                <div className="px-4 py-2">{user.salutation || "null"}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">City</div>
                                <div className="px-4 py-2">{user.city || "null"}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Address</div>
                                <div className="px-4 py-2">{user.address || "null"}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Country</div>
                                <div className="px-4 py-2">{user.country || "null"}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">State</div>
                                <div className="px-4 py-2">
                                    <div className="">{user.state || "null"}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Email</div>
                                <div className="sm:px-4 py-2 text-blue-800">{user.email || "null"}</div>
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">
                        🖊️ Edit Details
                    </button>

                </div>
                {isModalOpen && (
                    <EditModal
                        isOpen={isModalOpen}
                        user={user}
                        onCancel={() => setIsModalOpen(false)}
                        onOk={() => setIsModalOpen(false)}
                    />
                )}
            </div>}

            <div style={{ position: "absolute", width: "100vw", top: "50rem" }}>
                <Footer />
            </div>

            <h1></h1>
        </div>
    )
}

export default Profile