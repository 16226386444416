import React, { useState } from 'react';

const Fegister = () => {
  const [formData, setFormData] = useState({
    postdate: '',
    paymentdate: '',
    lastmodified: '',
    selectcountry: '',
    fee: '',
    feeamount: '',
    salutation: '',
    name: '',
    designation: '',
    organization: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    mobile: '',
    alternate_mobile: '',
    email: '',
    password: '',
    confirmpass: '',
    txnid: '',
    payamount: '',
    status: '',
    activestatus: 0,
    tempstatus: '',
    research1: '',
    research2: '',
    research3: '',
    affiliation1: '',
    affiliation2: '',
    affiliation3: '',
    membership1: '',
    membership2: '',
    membership3: '',
    membership4: '',
    award1: '',
    award2: '',
    award3: '',
    award4: '',
    paymentcharge: '',
    total_pay_with_charge: '',
    total_amt_exe_tax: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit} className="w-full max-w-lg">
        <div className="mb-4">
          <label htmlFor="postdate" className="block text-gray-700 text-sm font-bold mb-2">
            Post Date:
          </label>
          <input
            type="date"
            id="postdate"
            name="postdate"
            value={formData.postdate}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="paymentdate" className="block text-gray-700 text-sm font-bold mb-2">
            Payment Date:
          </label>
          <input
            type="datetime-local"
            id="paymentdate"
            name="paymentdate"
            value={formData.paymentdate}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="lastmodified" className="block text-gray-700 text-sm font-bold mb-2">
            Last Modified:
          </label>
          <input
            type="datetime-local"
            id="lastmodified"
            name="lastmodified"
            value={formData.lastmodified}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>

        {/* Add more form fields with appropriate styling classes */}
        {/* Remember to update the name, id, value, onChange, and any additional styling classes */}

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default Fegister;
