import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { async } from "rxjs";
import NavBar from "../NavBar";
import Footer from "../Footer";

const Login = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    // console.log(user);

    const passwordToggleHandler = () => {
        setShowPassword(!showPassword);
    }

    const submitClickHandler = async (e) => {
        setLoading(true);
        setError(false);
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        const data = await fetch('https://api.sesi.in/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        })
        // console.log("data : ", data.status);
        if (data.status != 200 && data.status != 201) {
            setLoading(false);
            return setError(true)
        }

        const res = await data.json();

        await localStorage.setItem('token', res.token);
        await localStorage.setItem('userId', res.userId);
        await sessionStorage.setItem('loggedIn', true);
        // await sessionStorage.

        setLoading(false);
        navigate("/");
    }

    return (
        <div>
            <NavBar />
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                {/* <script src="https://cdn.tailwindcss.com"></script> */}
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <NavLink to="/" className="text-6xl text-center font-light text-indigo-900 flex justify-content-center">
                            <img src="assets/img/logo/logo.png" className="img-fluid" alt="Logo" />
                        </NavLink>
                        <h1 className="mt-6 text-center text-4xl font-extrabold text-black">Membership Login</h1>
                        <h2 className="mt-6 text-center text-xl font-bold text-gray-900">Sign in to your account</h2>
                    </div>
                    <form className="mt-8 space-y-6" action="#" onSubmit={submitClickHandler}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Email address"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-b-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Password"
                                />
                            </div>
                        </div>

                        <div className="flex items-center">
                            <input
                                id="show"
                                name="show"
                                type="checkbox"
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                  border-gray-300 rounded"
                                onChange={passwordToggleHandler}
                            />
                            <label htmlFor="show" className="ml-2 block text-sm text-gray-900">
                                Show Password
                            </label>

                            <div className="text-sm ml-auto">
                                <NavLink to="/forgot-password" className="font-medium text-blue-500 hover:text-blue-600">
                                    Forgot your password?
                                </NavLink>
                            </div>
                        </div>

                        {/* <div className="flex items-center justify-between">


                            <div className="text-sm">
                                <a href="#" className="font-medium text-blue-500 hover:text-blue-600">
                                    Forgot your password?
                                </a>
                            </div>
                        </div> */}

                        {error && <div className="text-red-600">Username or Password is incorrect</div>}

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center
                py-2 px-4 border border-transparent text-sm font-medium
                rounded-md text-white bg-blue-500 hover:bg-blue-600
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-indigo-500"
                            >
                                {loading ? "Signing in..." : "Sign in"}
                            </button>
                        </div>
                        <div>
                            <NavLink
                                to="/register"
                                className="group relative w-full flex justify-center
                py-2 px-4 border border-transparent text-sm font-medium
                rounded-md text-white bg-blue-500 hover:bg-blue-600
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-indigo-500"
                            >
                                Sign Up
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login;