import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { Helmet } from "react-helmet";
import AwardsBlock from "../components/AwardsBlock";

class Scg extends Component {
  render() {
    return (
      <div>
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>
            Student Chapters Guidelines of SESI - Solar Energy Society of India
          </title>
          <meta
            name="description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta
            itemprop="name"
            content="SESI - Solar Energy Society of India"
          />
          <meta
            itemprop="description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            itemprop="image"
            content="https://sesi.in/assets/img/logo.png"
          />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://sesi.in/" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="SESI - Solar Energy Society of India"
          />
          <meta
            property="og:description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            property="og:image"
            content="https://sesi.in/assets/img/logo.png"
          />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="SESI - Solar Energy Society of India"
          />
          <meta
            name="twitter:description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            name="twitter:image"
            content="https://sesi.in/assets/img/logo.png"
          />
        </Helmet>
        {/* Navigation bar */}
        <NavBar />
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Student Chapter Guidelines</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Student Chapter Guidelines</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*====================  Scg page content ====================*/}
        <div className="text-center">
          <h2 className="m-6">
            {" "}
            Student Chapter <span className="text-[#00a3ff]"> Guidelines</span>
          </h2>
        </div>

        <div className="container">
          <p className="text-base">
            The Governing Council of Solar Energy Society of India invites
            attention to all Engineering College/ Polytechnic/ General college
            and University in the <br /> country for the formation of the
            Students’ Chapter in the campus with the following guidelines:
          </p>
          <br />
          <div className="mx-auto w-full p-6 bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500 lg:max-w-xl">
            <ol className="ml-6" style={{ listStyleType: "disc" }}>
              <li>
                All the students pursuing degree/ diploma in engineering/
                science from College/ University/ Institution/ polytechnic are
                eligible for enrolling themselves as a student member of SESI.
              </li>
              <li>
                The students shall apply for the student membership online
                (www.sesi.in) with the requisite onetime fee for the same is
                given below:
              </li>
              <li>
                Fee for Degree Engineering College Students’: Rs 500 (Five
                hundred only)
              </li>
              <li>
                Fee for Polytechnic/ B.Sc/ M.Sc./ General college Students’: Rs
                400 (Four hundred only)
              </li>
              <li>
                The faculty Member needs to forward the details of the enrolling
                students in the excel sheet as per the format given below
              </li>
              <br />

              <div class="relative overflow-x-auto sm:rounded-lg">
                <table className="border-solid border-black border-[1px] m-2">
                  <tr className="border-solid text-center">
                    <td className="border-black border-solid border-2 p-2">
                      Students Application Regd. Id
                    </td>
                    <td className="border-black border-solid border-2 p-2">
                      Name of Students
                    </td>
                    <td className="border-black border-solid border-2 p-2">
                      SESI Membership No
                    </td>
                    <td className="border-black border-solid border-2 p-2">
                      Mobile No.
                    </td>
                    <td className="border-black border-solid border-2 p-2">
                      Email id
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <li>
                SESI shall extend all possible support in transforming knowledge
                out of the box through technical quiz, industry visit, lecture,
                seminar, group <br /> discussion and model competition.
              </li>
              <li>
                Students can publish their research articles in SESI Journal
                also.
              </li>
            </ol>
          </div>
          <br />
          <p className="text-base">Contact Us:</p>

          <p className="text-base">
            <b> Secretary General </b> <br />
            Solar Energy Society of India <br />
            New Building -<br />
            Indian Society for Technical Educaation (ISTE) <br />
            Shaheed Jeet Singh Marg, <br />
            New Delhi - 110016
          </p>
          <p classNam e=" font-bold">
            WhatsApp No.: <a href="http://wa.me/+919355155776">9355155776</a>
          </p>
          <p className="text-lg">
            Email id: <a href="mailto:sg@sesi.in"> sg@sesi.in</a>
          </p>
          <br />
          <h3 className="text-center">
            ACTIVITIES TO BE CONDUCTED THROUGH STUDENT CHAPTER
          </h3>
          <br />
          <div className="mx-auto w-full p-6 bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500 lg:max-w-xl">
            <p className="text-base">
              SESI encourages to all the concerned to organise the following
              activities through out the year
            </p>
            <br />
            <ol className="ml-12" style={{ listStyleType: "disc" }}>
              <li>Technical Visit</li>
              <li> Seminar</li>
              <li> Lecture</li>
              <li> Workshop</li>
              <li> Paper Presentation</li>
              <li> Model Competition</li>
              <li> Poster Competition</li>
              <li> Technical Quiz</li>
              <li> Any other activities</li>
              <li>
                The activities report of the events supported by the photographs
                to be emailed for displaying the same in the website.
              </li>
            </ol>
          </div>
          <br />
          <h1 className="text-center pt-6 ">Funding for Activities</h1>
        </div>
        <AwardsBlock />
        <br />
        {/*====================  End of Scg page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Scg;
