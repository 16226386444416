import React, { Component } from "react";

class VicePresident extends Component {
  render() {
    /* team member data */

    let data = [
      {
        profileImage: "6.png",
        profileTitle: "Mr. Anil Mishra",
        profileDesignation: "Vice President",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
      {
        profileImage: "19.png",
        profileTitle: "Mr. H C Chawla",
        profileDesignation: "Vice President (North)",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
      {
        profileImage: "9.png",
        profileTitle: "Mr. Mukesh Pandey",
        profileDesignation: "Vice President (Central)",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
      {
        profileImage: "11.png",
        profileTitle: "Dr. E. Muthusamy",
        profileDesignation: "Vice President (South)",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
      {
        profileImage: "4.png",
        profileTitle: "Dr. Anil Kumar",
        profileDesignation: "Vice President (East)",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },
      {
        profileImage: "7.png",
        profileTitle: "Mr. G S Girase",
        profileDesignation: "Vice President (West)",
        profileEmail: "",
        socialLinks: {
          facebook: "//www.facebook.com",
          linkedin: "//www.linkedin.com",
        },
      },



    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="team sm:w-3/4 mx-auto bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500">
            <div className="image">
              <img src={`assets/img/team/${val.profileImage}`} alt={val.profileTitle} />
            </div>
            <div className="content">
              <h3 className="title">{val.profileTitle}</h3>
              <span className='text-blue-500 font-extrabold'>{val.profileDesignation}</span>

              <div className="social">
                <a href={val.socialLinks.facebook} className="facebook">
                  <i className="fa fa-facebook" />
                </a>
                {/* <a href={val.socialLinks.twitter} className="twitter"><i className="fa fa-twitter" /></a> */}
                <a href={val.socialLinks.linkedin} className="linkedin">
                  <i className="fa fa-linkedin" />
                </a>
                {/* <a href={val.socialLinks.googlePlus} className="google"><i className="fa fa-google-plus" /></a> */}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area section-space--inner--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <h2 className="section-title section-space--bottom--50">
                    Vice Presidents
                  </h2>
                </div>
              </div>
            </div>
            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default VicePresident;
