import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import Navb from "./navbarHelper/Navb";
import Marquee from "react-fast-marquee";
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div
          className={`header-area header-sticky header-sticky--default ${
            this.state.scroll > this.state.top ? "is-sticky" : ""
          }`}
        >
          <div className="header-area__desktop header-area__desktop--default">
            {/*=======  header top bar  =======*/}
            <div className="header-top-bar">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    {/* top bar left */}
                    <div className="top-bar-left-wrapper">
                      <div className="social-links social-links--white-topbar d-inline-block">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://m.facebook.com/groups/178012422252927/?ref=group_header&view=group"
                            >
                              <i className="zmdi zmdi-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://mobile.twitter.com/solarenergyind"
                            >
                              <i className="zmdi zmdi-twitter" />
                            </a>
                          </li>
                          {/* <li><a href="//vimeo.com"><i className="zmdi zmdi-vimeo" /></a></li> */}
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/solar-energy-society-of-india-sesi-/"
                            >
                              <i className="zmdi zmdi-linkedin-box" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UC6miIUEAESDHAzVJm4Tkq-g"
                            >
                              <i className="zmdi zmdi-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    {/* top bar right */}
                    <div className="top-bar-right-wrapper">
                      <Navb />
                      {/* <NavLink to="/login" className="ht-btn ht-btn--default d-inline-block">Login</NavLink> */}
                      {/* <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--default d-inline-block">Login</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className="header-info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="header-info-wrapper align-items-center">
                      {/* logo */}
                      <div className="logo">
                        <Link to="/">
                          <img
                            src="assets/img/logo/logo.png"
                            className="img-fluid"
                            alt="Logo"
                          />
                        </Link>
                      </div>
                      {/* header contact info */}
                      <div className="header-contact-info">
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-smartphone-android" />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Phone
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              <a href="tel:919355155876">+91 9355155876</a>
                            </p>
                          </div>
                        </div>
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-whatsapp" />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Whatsapp
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              {" "}
                              <a
                                target="_blank"
                                href="https://wa.me/+919355155775"
                              >
                                +91 9355155775
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-email" />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Email
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              {" "}
                              <a target="_blank" href="mailto:info@sesi.in">
                                info@sesi.in
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* mobile menu */}
                      <div
                        className="mobile-navigation-icon"
                        id="mobile-menu-trigger"
                        onClick={this.activeMobileMenu}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className="header-navigation-area default-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* header navigation */}
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav>
                          <ul>
                            {/* <li className="has-children has-children--multilevel-submenu"> */}
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/home`}>
                                {" "}
                                Home{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/about-us`}>
                                About
                              </Link>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              {/* <li> */}
                              <Link to={`${process.env.PUBLIC_URL}`}>
                                Gov. Council
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/president`}
                                  >
                                    Presidents
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/vice-president`}
                                  >
                                    Vice Presidents
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/secretary-treasurer`}
                                  >
                                    Secretary General & Treasurer
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/member`}>
                                    Members
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={`${process.env.PUBLIC_URL}/membership`}>
                                Membership
                              </Link>
                              <ul className="submenu">
                                <li>
                                <Link to={`${process.env.PUBLIC_URL}/membership`}>
                                Membership
                              </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/local-chapter-guidelines`}>
                                    Regional, State & Local
                                    Chapter Guidelines
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/student-chapter-guidelines`}>
                                    Student Chapter Guidelines
                                  </Link>
                                </li>
                                <li className="has-children has-children--multilevel-submenu">
                                  <Link to={`${process.env.PUBLIC_URL}`}>
                                    List of Chapters
                                  </Link>
                                  <ul className="submenu">
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/chapters`}>
                                        SESI Chapters
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/institutional-members`}>
                                        Institutional Members
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/regional-chapter`}>
                                        Regional Chapter
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/student-chapters`}>
                                        Student Chapter
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={`${process.env.PUBLIC_URL}`}>
                                Events
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/latest-events`}
                                  >
                                    Latest Events
                                  </Link>
                                </li>
                                {/* <li>
                                  <Link to={`${process.env.PUBLIC_URL}`}>
                                    Upcoming Events
                                  </Link>
                                </li> */}
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/past-events`}
                                  >
                                    Past Events
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/news-updates`}>
                              News Updates
                              </Link>
                            </li>
                            {/* <li className="has-children has-children--multilevel-submenu">
                              <Link to={`${process.env.PUBLIC_URL}`}>News</Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/news-updates`}
                                  >
                                    News Updates
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/`}>
                                    Newsletter
                                  </Link>
                                </li>
                              </ul>
                            </li> */}
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/innovation`}>
                                Innovation
                              </Link>
                            </li>

                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={`${process.env.PUBLIC_URL}`}>
                                SESI Exchange
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/sesi-Exchange`}
                                  >
                                    Exchange
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/sesi-query`}
                                  >
                                    Exchange Queries
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            {/* <li className="has-children has-children--multilevel-submenu"> */}

                            {/* <li className="has-children has-children--multilevel-submenu"> */}
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/blog`}>Blogs</Link>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/gallery`}>
                                Gallery
                              </Link>{" "}
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/awards`}>
                                Awards
                              </Link>{" "}
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/careers`}>
                                Careers
                              </Link>
                            </li>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                                Contact
                              </Link>{" "}
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <Marquee className="mq-bg">
            {/* <a download href="./assets/pdf/46th Annual General Body Meeting of SESI - Notice.pdf">
              46th Annual General Body Meeting of SESI - Notice
              </a>
            &nbsp; || &nbsp; */}
              <a target="_blsnk" href="mailto:dg@sesi.in">
                 Proposals invited for ICORE & ISCORE 2024, Mail us at dg@sesi.in
              </a>
              &nbsp; || &nbsp;
              <a download href="./assets/pdf/BrochureSESI.pdf">
                SESI Information Brochure &nbsp;
              </a>
              
            </Marquee>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>

        {/*====================  End of header area  ====================*/}

        {/* Mobile Menu */}
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
