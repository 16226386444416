import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { Helmet } from "react-helmet";

class RegionalChapter extends Component {
  render() {
    return (
      <div>
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>
            Regional Chapters of SESI - Solar Energy Society of India
          </title>
          <meta
            name="description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta
            itemprop="name"
            content="SESI - Solar Energy Society of India"
          />
          <meta
            itemprop="description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            itemprop="image"
            content="https://sesi.in/assets/img/logo.png"
          />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://sesi.in/" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="SESI - Solar Energy Society of India"
          />
          <meta
            property="og:description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            property="og:image"
            content="https://sesi.in/assets/img/logo.png"
          />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="SESI - Solar Energy Society of India"
          />
          <meta
            name="twitter:description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            name="twitter:image"
            content="https://sesi.in/assets/img/logo.png"
          />
        </Helmet>
        {/* Navigation bar */}
        <NavBar />

        {/*====================  Regional-chapter page content ====================*/}

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>LIST OF REGIONAL CHAPTERS</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>List of Regional Chapters</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <h1 className="text-center mt-20 mb-10">SESI Regional Chapters</h1>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6 col-12 section-space--bottom--30 px-6">
            <div className="team bg-white rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
              <div className="">
                <img
                  className="mx-auto pt-4"
                  src={`assets/img/landing/insti/7.png`}
                  alt="SRM"
                />
              </div>
              <div className="content">
                <h3 className="text-red">Southern Regional Chapter</h3>
                <h4 className="text-blue-500 pt-4 font-extrabold">
                  SRM Institute of Science & Technology
                </h4>
                <span className="pt-4 font-extrabold">
                  Dr. Soumyaranjan Routray
                </span>
                <div className="social">
                  <a href="mailto:soumyars@srmist.edu.in" className="">
                    <i className="zmdi zmdi-email" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Repeat the above code for the second card */}

          <div className="col-lg-3 col-sm-6 col-12 section-space--bottom--30 px-6">
            <div className="team bg-white rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
              <div className="">
                <img
                  className="mx-auto pt-4"
                  src={`assets/img/Student-chapter/13.png`}
                  alt="SRM"
                />
              </div>
              <div className="content">
                <h3 className="text-red">Western Regional Chapter</h3>
                <h4 className="text-blue-500 pt-4 font-extrabold">
                AISSMS College of Engineering, <br/> Pune
                </h4>
                <span className="pt-4 font-extrabold">
                Mr Nitin Mawale
                </span>
                <div className="social">
                  <a href="mailto:npmawale@aissmscoe.com" className="">
                    <i className="zmdi zmdi-email" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        {/*====================  End of Regional-chapter page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default RegionalChapter;
