import React, { useState } from 'react';
import axios from 'axios';
import  getepayPortal  from '../../components/Getepay_pg_react'; // Adjust the import path as needed

const UserRegistrationForm = () => {
    const [userInfo, setUserInfo] = useState({
        name: '',
        email: '',
        paymentTier: '200' // Default or based on user selection
    });

    const handleInputChange = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Configuration for Getepay
        const Config = {
            "Getepay Terminal Id": "Getepay.merchant61062@icici",
            "Getepay Key": "JoYPd+qso9s7T+Ebj8pi4Wl8i+AHLv+5UNJxA3JkDgY=",
            "Getepay IV": "hlnuyA9b4YxDq6oJSZFl8g==",
            "Getepay Url":
              "https://pay1.getepay.in:8443/getepayPortal/pg/generateInvoice",
          };

        // Data for payment
        const data = {
            mid: "108",
            amount: userInfo.paymentTier,
            merchantTransactionId: "sd12121",
            transactionDate: "Mon Oct 03 13:54:33 IST 2022",
            terminalId: "Getepay.merchant61062@icici",
            udf1: "1234567890",
            udf2: "Test",
            udf3: "tech.sesi@gmail.com",
            udf4: "",
            udf5: "",
            udf6: "",
            udf7: "",
            udf8: "",
            udf9: "",
            udf10: "",
            ru: "",
            callbackUrl: "/register",
            currency: "INR",
            paymentMode: "ALL",
            bankId: "",
            txnType: "single",
            productType: "IPG",
            txnNote: "Test Txn",
            vpa: "Getepay.merchant61062@icici",
          };

        // Initiate payment process
        getepayPortal(data, Config);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                placeholder="Name"
                value={userInfo.name}
                onChange={handleInputChange}
            />
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={userInfo.email}
                onChange={handleInputChange}
            />
            <select name="paymentTier" value={userInfo.paymentTier} onChange={handleInputChange}>
                <option value="200">200</option>
                <option value="2">2</option>
                <option value="5000">5000</option>
                <option value="6000">6000</option>
                <option value="20000">20000</option>
            </select>
            <button type="submit">Proceed to Payment</button>
        </form>
    );
};

export default UserRegistrationForm;
