import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import {Helmet} from "react-helmet";
import SesiAwards from "../components/SesiAwards";
class Awards extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
              <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>Awards by SESI - Solar Energy Society of India</title>
<meta name="description" content="SESI - Solar Energy Society of India, and its mission to promote solar energy adoption."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="Awards by SESI - Solar Energy Society of India"/>
<meta itemprop="description" content=" SESI - Solar Energy Society of India, and its mission to promote solar energy adoption."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Awards by SESI - Solar Energy Society of India"/>
<meta property="og:description" content="SESI - Solar Energy Society of India, and its mission to promote solar energy adoption."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Awards by SESI - Solar Energy Society of India"/>
<meta name="twitter:description" content=" SESI - Solar Energy Society of India, and its mission to promote solar energy adoption."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Awards</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Awards</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

<SesiAwards/>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Awards;
