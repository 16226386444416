import React, { Component, useState } from "react";

class InstitutionalBlocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      imagesPerPage: 8,
    };
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
    // Scroll to the top of the page when a page number is clicked
    window.scrollTo(0, 0);
  };
  render() {
    /* team member data */

    let data = [
      {
        logo: "1.png",
        college: "Guru Nanak Institute of Technology",
        state: "West Bengal",
        num: "IM/2019/01",
        mail: "principal_gnit@jisgroup.org",
        serviceUrl: "https://gnit.ac.in/",
      },
      {
        logo: "2.png",
        college: "Institute of Engg and Management",
        state: "West Bengal",
        num: "IM/2019/02",
        mail: "arun.bar@iemcal.com",
        serviceUrl: "https://iem.edu.in",
      },
      {
        logo: "3.png",
        college: "BIT Institute of Technology",
        state: "Andhra Pradesh",
        num: "IM/2019/03",
        mail: "director.bitit@gmail.com",
        serviceUrl: "https://bitit.org/",
      },
      {
        logo: "4.png",
        college: "Onix Structure Private Limited",
        state: "Gujarat",
        num: "IM/2019/04",
        mail: "marketing@onixstructure.in",
        serviceUrl: "https://www.onixgroup.in/",
      },
      {
        logo: "5.png",
        college: "Gandhi Institute for Technology",
        state: "Odisha",
        num: "IM/2019/05",
        mail: "amar.das@gift.edu.in",
        serviceUrl: "https://autonomous.gift.edu.in/",
      },
      {
        logo: "6.png",
        college: "Malla Reddy Engineering college",
        state: "Telangana",
        num: "IM/2019/06",
        mail: "eeehod@mrec.ac.in",
        serviceUrl: "https://www.mrec.ac.in/",
      },
      {
        logo: "7.png",
        college: "SRM Institute of Science & Technology",
        state: "Tamil Nadu",
        num: "IM/2021/07",
        mail: "hod.ece.ktr@srmist.edu.in",
        serviceUrl: "https://www.srmist.edu.in/",
      },
      {
        logo: "8.png",
        college: "Fine Envirotech Engineers",
        state: "Maharashtra",
        num: "IM/2021/08",
        mail: "reporting@3psadvisory.com",
        serviceUrl: "https://www.fineenvirotech.com/",
      },
      {
        logo: "9.png",
        college: "Maharishi University of Information Technology",
        state: "Uttar Pradesh",
        num: "IM/2021/09",
        mail: "vc@muit.in",
        serviceUrl: "https://muit.in/",
      },
      {
        logo: "10.png",
        college: "All India Shri Shivaji Memorial Societies College of Engineering",
        state: "Maharashtra",
        num: "IM/2021/10",
        mail: "npmawale@aissmscoe.com",
        serviceUrl: "https://aissmscoe.com/",
      },
      {
        logo: "11.png",
        college: "Coimbatore Institute \n of \n Technology",
        state: "Tamil Nadu",
        num: "IM/2022/11",
        mail: "principal@cit.edu.in",
        serviceUrl: "https://www.cit.edu.in/",
      },
      {
        logo: "12.png",
        college: "Konark Institute of Science and Technology",
        state: "Odisha",
        num: "IM/2022/12",
        mail: "principal@kist.ac.in",
        serviceUrl: "https://kist.ac.in/",
      },
      {
        logo: "13.png",
        college: "Kumaraguru College \n of Technology\n" + "\n",
        state: "Tamil Nadu",
        num: "IM/2022/13",
        mail: "vinothkumar.n.eee@kct.ac.in",
        serviceUrl: "https://www.kct.ac.in/",
      },
      {
        logo: "14.png",
        college: "Balasore College of Engineering & Technology",
        state: "Odisha",
        num: "IM/2022/14",
        mail: "principal@bcetodisha.ac.in",
        serviceUrl: "https://bcetodisha.ac.in/",
      },
      {
        logo: "15.png",
        college: "Amrita College of Engineering & Technology",
        state: "Tamil Nadu",
        num: "IM/2022/15",
        mail: "principal@amrita.edu.in",
        serviceUrl: "https://www.amrita.edu.in/",
      },
      {
        logo: "16.png",
        college: "Graphic Era \n" + "\n\n",
        state: "Uttarakhand",
        num: "IM/2022/16",
        mail: "registrar@geu.ac.in",
        serviceUrl: "https://geu.ac.in/",
      },
      {
        logo: "17.png",
        college: "Astone Energy Private Limited",
        state: "Delhi",
        num: "IM/2022/17",
        mail: "info@astoneenergy.com",
        serviceUrl: "http://www.astoneenergy.com/",
      },
      {
        logo: "18.png",
        college: "Sherbrooke Aluminium Products",
        state: "Kerala",
        num: "IM/2023/18",
        mail: "koshy@sherbrookeinc.com",
        serviceUrl: "https://www.sherbrookeinc.com/",
      },
      {
        logo: "19.png",
        college: "Desai Techno Solution Pvt. Ltd.",
        state: "Maharashtra",
        num: "IM/2023/19",
        mail: "ranad6707@gmail.com",
        serviceUrl: "https://www.desaitech.in/",
      },
      {
        logo: "20.png",
        college: "Symbiosis skills and Professional University",
        state: "Maharashtra",
        num: "IM/2023/20",
        mail: "sagar.wankhede@sspu.ac.in",
        serviceUrl: "https://sspu.ac.in/",
      },
      {
        logo: "21.png",
        college: "Gandhi Institute of Technology & Management",
        state: "Odisha",
        num: "IM/2023/21",
        mail: "principalgitam@yahoo.com",
        serviceUrl: "https://www.gitam.edu/",
      },
      {
        logo: "22.png",
        college: "AHAsolar Technologies Limited \n" + "\n",
        state: "Gujarat",
        num: "IM/2023/22",
        mail: "kshitiz.v@ahasolar.in",
        serviceUrl: "https://www.ahasolar.in/",
      },
      {
        logo: "23.png",
        college: "Vellore Institute of Technology\n" + "\n",
        state: "Tamil Nadu",
        num: "IM/2023/23",
        mail: "iis@vit.ac.in",
        serviceUrl: "https://vit.ac.in/",
      },
      {
        logo: "24.png",
        college: "Hoshang Patel Tech Centre\n" + "\n",
        state: "Maharashtra",
        num: "IM/2023/24",
        mail: "ceo@hoshang-tech.in",
        serviceUrl: "http://hoshang-tech.in/",
      },
      {
        logo: "25.png",
        college: "Indradhanush Gas Grid Limited\n" + "\n",
        state: "Assam",
        num: "IM/2023/25",
        mail: "ceo.cell@iggl.co.in",
        serviceUrl: "https://iggl.co.in/",
      },
      {
        logo: "26.png",
        college: "Maulana Abul Kalam Azad University of Technology",
        state: "West Bengal",
        num: "IM/2023/26",
        mail: "registrar@makautwb.ac.in",
        serviceUrl: "https://makautwb.ac.in/",
      },
      {
        logo: "27.png",
        college: "SRM Institute of Science & Technology (Delhi NCR Campus)",
        state: "Uttar Pradesh",
        num: "IM/2023/27",
        mail: "director.ncr@srmist.edu.in",
        serviceUrl: "https://www.srmup.in/",
      },
      {
        logo: "28.png",
        college: "Kalinga Institute of Social Sciences ",
        state: "Odisha",
        num: "IM/2024/28",
        mail: "registrar@kiss.ac.in",
        serviceUrl: "https://kiss.ac.in/",
      },
      {
        logo: "29.png",
        college: "New Polytechnic Kolhapur",
        state: "Maharashtra",
        num: "IM/2024/30",
        mail: "npkolhapur@gmail.com",
        serviceUrl: "https://newpolytechnic.com/",
      },
      {
        logo: "30.png",
        college: "SRI VENKATESHWARAA COLLEGE OF ENGINEERING & TECHNOLOGY",
        state: "Pondicherry",
        num: "IM/2024/30",
        mail: "principal@svcet.ac.in",
        serviceUrl: "https://svcet.ac.in/",
      },
    ];

    // Pagination logic
    const { currentPage, imagesPerPage } = this.state;
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = data.slice(indexOfFirstImage, indexOfLastImage);

    let Datalist = currentImages.map((val, i) => {
      return (

        <div
          className="col-lg-3 col-sm-6 col-12 section-space--bottom--30  px-6"
        >
          <div className="team rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">

            <a target="_blank" href={val.serviceUrl}>
              <img className="mx-auto pt-4 w-3/4" src={`assets/img/landing/insti/${val.logo}`} alt={val.college} />
            </a>
            <div className="content">
              <h3 style={{ whiteSpace: "pre-line" }} className="text-red">{val.college}</h3>
              <h4 className='text-blue-500 pt-4 font-extrabold'>{val.state}</h4>
              <span className="pt-4 font-extrabold">IM Number: &nbsp;{val.num}</span>
              <div className="social">
                <a href={'mailto:' + val.mail} className="">
                  <i className="zmdi zmdi-email" />
                </a>
              </div>

            </div>
          </div>

        </div>
      );
    });

    // Page numbers for pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / imagesPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? "active" : ""}
          onClick={this.handleClick}
        >
          {number}
        </li>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area">
          <div className="container">
            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-container">
              <ul className="pagination">{renderPageNumbers}</ul>
            </div>
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default InstitutionalBlocks;
