import React, { Component } from 'react';

class Exchange extends Component{
    render(){

        
        /* team member data */
        
        let data = [
            {profileImage: "e1.jpg", profileTitle: "Aditya \n Renewables"},
            {profileImage: "e2.jpg", profileTitle: "Power-One Micro Systems Pvt Ltd."},
            {profileImage: "e3.jpg", profileTitle: "SN Electric Controls & Equipments"},
            {profileImage: "e4.jpg", profileTitle: "OPG Energy Private Limited"},
            {profileImage: "e6.jpg", profileTitle: "Avante Global Services Pvt Ltd"},
            {profileImage: "e7.jpg", profileTitle: "Bhambri \n Enterprises"},
            {profileImage: "e8.jpg", profileTitle: "Electra\n Ventures"},
            {profileImage: "e9.jpg", profileTitle: "J.P. \nConsultants"},
            {profileImage: "e10.jpg", profileTitle: "Assets and Equipments Inc"},
            {profileImage: "e11.jpg", profileTitle: "Maharishi Solar Technology Pvt. Ltd"},
            {profileImage: "e12.jpg", profileTitle: "Sunfuel Technologies LLP"},
            {profileImage: "e13.jpg", profileTitle: "Solaris 360\n"+"\n"},
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <div
                className="col-lg-3 col-sm-6 col-12 section-space--bottom--30  px-6"
               > 
                 <div className="team bg-white  rounded-l shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
       
                        <div className="image">
                        <img src={`assets/img/team/${val.profileImage}`} alt="" />
                        </div>
                        <div className="content">
                        <h3 style={{whiteSpace: "pre-line"}}  className="title">{val.profileTitle}</h3>                        
                        </div>
                    </div>
                </div>
            )
        });
        return(
            <div>
                {/*====================  team member area ====================*/}
                <div className="team-member-area section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">SESI EXCHANGE </h2>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="team-member-wrapper">
                        <div className="row">
                            {/* team member list */}
                            {Datalist}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of team member area  ====================*/}

            </div>
        )
    }
}

export default Exchange;