import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import TeamMemberGrid from "../components/TeamMemberGrid";
import SecrataryMember from "../components/SecrataryMember";
import {Helmet} from "react-helmet";
class SGeneral extends Component {
  render() {
    let data = [
      {
        pageLink: "service-details-left-sidebar",
        img: "service1.jpg",
        iconClass: "flaticon-002-welding",
        serviceTitle: "Land Minning",
        serviceSubtitle:
          "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        pageLink: "service-details-left-sidebar",
        img: "service2.jpg",
        iconClass: "flaticon-004-walkie-talkie",
        serviceTitle: "Work Management",
        serviceSubtitle:
          "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        pageLink: "service-details-left-sidebar",
        img: "service3.jpg",
        iconClass: "flaticon-015-cart",
        serviceTitle: "Material Engineering",
        serviceSubtitle:
          "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        pageLink: "service-details-left-sidebar",
        img: "service-3.jpg",
        iconClass: "flaticon-010-tank-1",
        serviceTitle: "Power and Energy",
        serviceSubtitle:
          "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        pageLink: "service-details-left-sidebar",
        img: "service-2.jpg",
        iconClass: "flaticon-004-walkie-talkie",
        serviceTitle: "Land Minning",
        serviceSubtitle:
          "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      },
      {
        pageLink: "service-details-left-sidebar",
        img: "service-1.jpg",
        iconClass: "flaticon-002-welding",
        serviceTitle: "Work Management",
        serviceSubtitle:
          "Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor"
      }
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-md-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="service-grid-item">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  <img
                    src={`assets/img/service/${val.img}`}
                    className="img-fluid"
                    alt="Service Grid"
                  />
                </a>
              </div>
              <div className="icon">
                <i className={val.iconClass} />
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  {val.serviceTitle}
                </a>
              </h3>
              <p className="subtitle">{val.serviceSubtitle}</p>
              <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link"
              >
                SEE MORE
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
              <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>Secretary General & Treasurer of SESI - Solar Energy Society of India</title>
<meta name="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="Secretary General & Treasurer of SESI - Solar Energy Society of India"/>
<meta itemprop="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Secretary General & Treasurer of SESI - Solar Energy Society of India"/>
<meta property="og:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Secretary General & Treasurer of SESI - Solar Energy Society of India"/>
<meta name="twitter:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Secretary General & Treasurer</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Secretary General & Treasurer</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  service page content ====================*/}
        {/* <div className="page-wrapper section-space--inner--120"> */}
          {/*Service section start*/}
         
         
          {/* <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          {/*Service section end*/}
        {/* </div> */}
        <SecrataryMember />
        {/*====================  End of service page content  ====================*/}

        {/* Brand logo */}
        {/* <BrandLogoSlider background="grey-bg" /> */}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default SGeneral;
