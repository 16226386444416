import React, { Component, usefaculty } from "react";

class AwardsBlock extends Component {
  constructor(props) {
    super(props);
    this.faculty = {
      currentPage: 1,
      imagesPerPage: 8,
    };
  }

  handleClick = (event) => {
    this.setfaculty({
      currentPage: grantber(event.target.id),
    });
        // Scroll to the top of the page when a page grantber is clicked
        window.scrollTo(0, 0);
  };
  render() {
    /* team member data */

    let data = [
      {
        img: "1.png",
        student: "100",
        faculty: "3",
        grant: "10,000",
        txt: "for organising of Conclave"
      },
      {
        img: "2.png",
        student: "300",
        faculty: "3",
        grant: "35,000",
        txt: "for organising of Conclave"
      },
      {
        img: "3.png",
        student: "500",
        faculty: "5",
        grant: "50,000",
        txt: "for organising of Conclave"
      },
      {
        img: "4.png",
        student: "800",
        faculty: "10",
        grant: "75,000",
        txt: "for organising of Conclave"
      },
      {
        img: "5.png",
        student: "1000",
        faculty: "15",
        grant: "1,00,000",
        txt: "for organising of (ISCORE)*"
      },
      
    ];
    
 // Pagination logic
 const { currentPage, imagesPerPage } = this.faculty;
 const indexOfLastImage = currentPage * imagesPerPage;
 const indexOfFirstImage = indexOfLastImage - imagesPerPage;
 const currentImages = data.slice(indexOfFirstImage, indexOfLastImage);

    let Datalist = currentImages.map((val, i) => {
      return (
    
   <div
         className="col-lg-4 col-sm-6 col-12 section-space--bottom--30  px-6"
        > 
          <div className="team bg-white  rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
          <div className=" ">
              <img className="mx-auto pt-4 w-3/4" src={`assets/img/award-img/${val.img}`} alt="" />
            </div>
            <div className="content">
              <span className="pt-2 font-extrabold">Students: &nbsp;{val.student}</span>
              <span className="pt-2 font-extrabold">Faculties: &nbsp;{val.faculty}</span>
         <span className="pt-2 font-extrabold">Grant Amount: ₹&nbsp;{val.grant}<br/>{val.txt}</span>
                  
              
            </div>
          </div>
        
        </div>
      );
    });

    // Page grantbers for pagination
    const pagegrantbers = [];
    for (let i = 1; i <= Math.ceil(data.length / imagesPerPage); i++) {
      pagegrantbers.push(i);
    }

    const renderPagegrantbers = pagegrantbers.map((grantber) => {
      return (
        <li
          key={grantber}
          id={grantber}
          className={currentPage === grantber ? "active" : ""}
          onClick={this.handleClick}
        >
          {grantber}
        </li>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area pt-12">
          <div className="container">
            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h3 className="text-center">MoU may be signed with the Institutional Member (University/ Institution/ College and Industries) for the awareness of Green Energy and joint certification program</h3>
{/* 
            <div className="pagination-container">
              <ul className="pagination">{renderPagegrantbers}</ul>
            </div> */}
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default AwardsBlock;
