import React, { useState } from 'react'
import NavBar from '../NavBar'
import { NavLink } from 'react-router-dom'
import Footer from '../Footer';

const ForgPass1 = () => {
    const [email, setEmail] = useState();
    const[loading, setLoading] = useState(false);
    const[sent, setSent] = useState(false);

    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        fetch(`https://api.sesi.in/user/getId/${email}`)
            .then(res => {
                return res.json();
            })
            .then(resData => {
                console.log(resData)
                console.log(resData);
                return Email.send({
                    Host: "smtp.elasticemail.com",
                    Username: "tyagidivyam21@gmail.com",
                    Password: "B2B68522D45466ABE6108F6BB3FDDD6AA4F7",
                    Port: 2525,
                    To: email,
                    From: "tech.sesi@gmail.com",
                    Subject: "SESI Membership Registration",
                    Body: `Greetings User,<br>
                    Click on the link or copy paste https://sesi.in/reset-password/${resData.UserId} to Reset your password<br>
                    Regards<br>
                    Solar Energy Society of India
                  `
                })
            })
            .then((res)=>{
                setLoading(false);
                setSent(true);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div>
            <NavBar />
            <div className="min-h-full flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div>
                    <NavLink to="/" className="text-6xl text-center font-light text-indigo-900 flex justify-content-center">
                        <img src="assets/img/logo/logo.png" className="img-fluid" alt="Logo" />
                    </NavLink>
                    <h1 className="mt-6 text-center text-4xl font-extrabold text-black">Forgot Password</h1>
                    <h2 className="mt-6 text-center text-xl font-bold text-gray-900">Enter your registered Email Address</h2>
                </div>
                <form className="mt-8 space-y-6" action="#">
                    <div className="rounded-md shadow-sm">
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            onChange={emailChangeHandler}
                            autoComplete="email"
                            required
                            className="appearance-none rounded-none relative block
                  w-72 px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Email address"
                        />
                    </div>
                    {sent && <div className='text-green-600'>* A Password Reset link has been sent to the entered email address. (Check your spam folder) </div>}
                    <div className='mt-14'>
                        <button
                            onClick={submitHandler}
                            className="group relative w-full flex justify-center
                py-2 px-4 border border-transparent text-sm font-medium
                rounded-md text-white bg-blue-500 hover:bg-blue-600
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-indigo-500"
                        >
                            {loading ? "Wait..." : "Submit"}
                        </button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default ForgPass1