import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { Helmet } from "react-helmet";
import MemberIcon from "../components/MemberIcon";
class Membership extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>SESI Memberships - Solar Energy Society of India</title>
          <meta name="description" content="Join SESI - Solar Energy Society of India and become a part of the solar revolution" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content="SESI Memberships - Solar Energy Society of India" />
          <meta itemprop="description" content="Join SESI - Solar Energy Society of India and become a part of the solar revolution" />
          <meta itemprop="image" content="https://sesi.in/assets/img/logo.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://sesi.in/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="SESI Memberships - Solar Energy Society of India" />
          <meta property="og:description" content="Join SESI - Solar Energy Society of India and become a part of the solar revolution" />
          <meta property="og:image" content="https://sesi.in/assets/img/logo.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="SESI Memberships - Solar Energy Society of India" />
          <meta name="twitter:description" content="Join SESI - Solar Energy Society of India and become a part of the solar revolution" />
          <meta name="twitter:image" content="https://sesi.in/assets/img/logo.png" />

        </Helmet>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Membership</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Membership</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--120">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src="assets/img/about/member.png" alt="SESI" />
                    {/* <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button onClick={this.openModal}>
                        <i className="ion-ios-play-outline" />
                      </button>
                    </span> */}
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3 className="text-bold">Welcome to SESI</h3>
                    <h1>45 Years of Experience in Industry</h1>
                    <h4 className="text-bold">
                      The Solar Energy of India (SESI), established in 1978
                    </h4>
                    <p>
                      SESI has total membership strength of 3500 at present. Majority of them are engaged in the research, development, manufacturing and programme implementation activities etc.
                      <br /><br />
                      SESI has an ambitious goal to put across its message of using Renewable energy far and wide. To meet this objective, it invites all those interested to enroll themselves as members using the link available below. As members, they will be entitled to free subscription of SESI publications i.e. Journal and Newsletter.


                    </p>
                    <a href={`${process.env.PUBLIC_URL}/login`} className="ht-btn ht-btn--round">Join Now</a>

                    {/* <a
                      href="services"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Our Services
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Feature Icon */}
          <MemberIcon background="grey-bg" />

          {/*About section start*/}
          <div className="about-section section-space--inner--100">
            <div className="container">
              <div className="about-wrapper row">
                <div className="col-sm-6 col-12 order-1 order-lg-2">
                  <div className="about-image about-image-1">
                    <img src="assets/img/about/r1.png" alt="SESI-register" />
                  </div>
                </div>

                <div className="col-sm-6 col-12 order-2 order-lg-3">
                  <div className="about-image about-image-2">
                    <img src="assets/img/about/r2.png" alt="SESI-register" />
                  </div>
                </div>

                <div className="col-lg-6 col-12 order-3 order-lg-1">
                  <div className="about-content about-content-1">
                    <h2>
                      <span>Register Online :</span>
                    </h2>
                    <p>
                      Kindly fill in the online registration form along with the details of bank draft and send the requisite fees by bank draft in favor of “Solar Energy Society of India” payable at New Delhi to us by post.

                    </p>
                    <a href={`/login`} className="ht-btn ht-btn--round">Register Now</a>
                    <br /> <br />
                    <h2>
                      <span>Register by Post :</span>
                    </h2>
                    <p>
                      Desirous individual can download the application form and post the same along with the requisite fees by bank draft in favor of “Solar Energy Society of India” payable at New Delhi to us by post.</p>
                    <a download href={`/assets/pdf/Form_SESI.pdf`} className="ht-btn ht-btn--round">Download Now</a>
                    {/* <a href={`${process.env.PUBLIC_URL}/about-us`} className="ht-btn ht-btn--round">Join Now</a> */}
                  </div>
                </div>

                <div className="col-lg-6 col-12 order-4">
                  <div className="about-content about-content-2">
                    <div className="about-content about-content-1">
                      <h3>
                        <span>
                          <br /> Kindly send the application form along with the requisite fee by bank draft or by post to us at the following address:
                        </span>
                      </h3>
                      <p>
                        <br />
                        <h4> The Secretary General</h4>
                        <b>  Solar Energy Society of India </b> <br />

                        New Building - <br />
                        Indian Society for Technical Education (ISTE), <br />
                        Shaheed Jeet Singh Marg,<br /> New Delhi 110016

                        <br />
                        <br />
                        <a href="http://wa.me+919355155776">
                          WhatsApp: +91 9355155776 </a>
                        <br />
                        <a href="mailto:sg@sesi.in">
                          E-Mail Id: sg@sesi.in</a>

                      </p>
                    </div>

                    {/* <a
                      href={`${process.env.PUBLIC_URL}/contact-us`}
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Contact us
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>



          {/* Brand logo */}
          {/* <BrandLogoSlider background="" /> */}
        </div>
        <br /><br />
        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Membership;
