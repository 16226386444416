import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import MobileMenu from "../components/MobileMenu";
class LatestEvents extends Component {
  render() {
    
    let data = [
      {
        embedId: "ef6Ce-zrlGo",
        pageLink:"https://youtu.be/ef6Ce-zrlGo",
        projectTitle:"Solar World Congress 2023 in New Delhi"
      },
      {
        embedId: "oo7L5kSEqM4",
        pageLink:"https://youtu.be/oo7L5kSEqM4",
        projectTitle:"Launch of EV Charging Station Technician (Technical Handbook)"
      },
      {
        embedId: "8wBZ46WZhNc",
        pageLink:"https://youtu.be/8wBZ46WZhNc",
        projectTitle:"Renewable Energy in Indian Universities"
      },
      {
        embedId: "I5upX_y5aRk",
        pageLink:"https://youtu.be/I5upX_y5aRk",
        projectTitle:"Solar Energy: An Agent of Change"
      },
      {
        embedId: "jKrpGZpyTbw",
        pageLink:"https://youtu.be/jKrpGZpyTbw",
        projectTitle:"Step Ahead for Decentralised Solar"
      },
      {
        embedId: "s0RvOZT76HA",
        pageLink:"https://youtu.be/s0RvOZT76HA",
        projectTitle:"Solar Manufacturing in India"
      } 
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >

          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
              <iframe
      className="img-fluid mx-auto pt-5"
      src={`https://www.youtube.com/embed/${val.embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />

              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a target="_blank" href={`${val.pageLink}`}>
                  {val.projectTitle}
                </a>
              </h3>
              <br />
           
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
              <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>Latest events of SESI - Solar Energy Society of India</title>
<meta name="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="Latest events of SESI - Solar Energy Society of India"/>
<meta itemprop="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Latest events of SESI - Solar Energy Society of India"/>
<meta property="og:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Latest events of SESI - Solar Energy Society of India"/>
<meta name="twitter:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Latest Events</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Latest Events</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project page content ====================*/}
         {/*Projects section start*/}


        <div className="page-wrapper section-space--inner--120">
          <div className="project-section text-center">
          <h2 className="section-title section-space--bottom--50">
            Latest Events
            </h2>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <MobileMenu />
      </div>
    );
  }
}

export default LatestEvents;
