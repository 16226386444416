import React, { useEffect, useState } from 'react'
import FormUI from "../../components/formsUI/FormUI"
import { NavLink, useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar';

const RegForm2 = (props) => {

  const navigate = useNavigate();

  const [member, setMember] = useState();
  const [loading, setLoading] = useState(false);

  // console.log("member reg : ", member);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('member'));
    if (items) {
      setMember(items);
    }
  }, [])

  const formChangeHandler = (e) => {
    e.preventDefault();
    setMember({ ...member, [e.target.name]: e.target.value });
    // console.log(e.target.name," : ",e.target.value);   
  }

  const submitClickHandler = () => {
    // event.preventDefault();
    setLoading(true);


    fetch('https://api.sesi.in/auth/signup', {
      method: "PUT",
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify(member)
    })
      .then((response) => {
        console.log(response)
        Email.send({
          Host: "smtp.elasticemail.com",
          Username: "tyagidivyam21@gmail.com",
          Password: "B2B68522D45466ABE6108F6BB3FDDD6AA4F7",
          Port: 2525,
          To: member.email,
          From: "tech.sesi@gmail.com",
          Subject: "SESI Membership Registration",
          Body: `
          Hello ${member.name}, you have successfully paid the amount and your application has been successfully submited.
          Below are the details : ${JSON.stringify(member)}
        `
        })
      })


      .then(
        (message) => {
          alert("Your account is created successfully. Kindly Login")
          if (message === "OK") {
            alert("An Email has been sent. Check your mail(Spam Folder)");
          }
          setLoading(false);
          // setInnovations({});
          localStorage.removeItem('member');
          // console.log("hahahqa")
          navigate("/login");
        }
      );
    // console.log(member);
  }

  return (
    <div>
      <NavBar />
      {/* <NavLink to="/" className="text-6xl text-center font-light flex justify-content-left m-2">
        <img src="assets/img/logo/logo.png" className="img-fluid" alt="Logo" />
      </NavLink> */}
      <FormUI title="Membership Form">
        {/* <h1>hii, {member.name}</h1> */}
        <div className="mb-2 space-y-5">

          <div className="mb-2">
            <label
              htmlFor="res1"
              className="block text-sm font-semibold text-gray-800"
            >
              Research 1:
            </label>
            <textarea
              onChange={formChangeHandler}
              name='research1'
              type="textarea"
              className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="res2"
              className="block text-sm font-semibold text-gray-800"
            >
              Research 2:
            </label>
            <textarea
              onChange={formChangeHandler}
              name='research2'
              type="textarea"
              className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="res3"
              className="block text-sm font-semibold text-gray-800"
            >
              Research 3:
            </label>
            <textarea
              onChange={formChangeHandler}
              name='research3'
              type="textarea"
              className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          {/* Affiliation */}

          <div className="mb-2">
            <label
              htmlFor="aff1"
              className="block text-sm font-semibold text-gray-800"
            >
              Affiliation 1:
            </label>
            <input
              onChange={formChangeHandler}
              name='affiliation1'
              type="text"
              className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="aff2"
              className="block text-sm font-semibold text-gray-800"
            >
              Affiliation 2:
            </label>
            <input
              onChange={formChangeHandler}
              name='affiliation2'
              type="text"
              className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="aff3"
              className="block text-sm font-semibold text-gray-800"
            >
              Affiliation 3:
            </label>
            <input
              onChange={formChangeHandler}
              name='affiliation3'
              type="text"
              className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          {/* Membership */}

          <div className='flex space-x-6'>
            <div>
              <label
                htmlFor="mem1"
                className="block text-sm font-semibold text-gray-800"
              >
                Membership 1 :
              </label>
              <input
                type="text"
                id='mem1'
                name='membership1'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div>

              <label
                htmlFor="mem2"
                className="block text-sm font-semibold text-gray-800"
              >
                Membership 2 :
              </label>
              <input
                type="text"
                id='mem2'
                name='membership2'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>

          <div className='flex space-x-6'>
            <div>
              <label
                htmlFor="mem3"
                className="block text-sm font-semibold text-gray-800"
              >
                Membership 3 :
              </label>
              <input
                type="text"
                id='mem3'
                name='membership3'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div>

              <label
                htmlFor="mem4"
                className="block text-sm font-semibold text-gray-800"
              >
                Membership 4 :
              </label>
              <input
                type="text"
                id='mem4'
                name='membership4'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>

          {/* Awards */}

          <div className='flex space-x-6'>
            <div>
              <label
                htmlFor="awd1"
                className="block text-sm font-semibold text-gray-800"
              >
                Award 1 :
              </label>
              <input
                type="text"
                id='awd1'
                name='awd1'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div>

              <label
                htmlFor="awd2"
                className="block text-sm font-semibold text-gray-800"
              >
                Award 2 :
              </label>
              <input
                type="text"
                id='awd2'
                name='awd2'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>

          <div className='flex space-x-6'>
            <div>
              <label
                htmlFor="awd3"
                className="block text-sm font-semibold text-gray-800"
              >
                Award 3 :
              </label>
              <input
                type="text"
                id='awd3'
                name='awd3'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div>

              <label
                htmlFor="awd4"
                className="block text-sm font-semibold text-gray-800"
              >
                Award 4 :
              </label>
              <input
                type="text"
                id='awd4'
                name='awd4'
                onChange={formChangeHandler}
                className="block w-full px-4 py-2 mt-2 text-blue-500 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>
        </div>

        <div onClick={() => submitClickHandler()} className="w-full mt-2 text-center px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-600 cursor-pointer focus:outline-none focus:bg-blue-500">
          {!loading ? "Submit" : "loading..."}
        </div>

        <p className="mt-8 text-xs font-light text-center text-gray-700">
          {" "}
          Already a Member?{" "}
          <NavLink
            to="/login"
            className="font-medium text-blue-500 hover:underline"
          >
            Login
          </NavLink>
        </p>

      </FormUI>
    </div>
  )
}

export default RegForm2;