import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SesiGuidelines from "../components/SesiGuidlines";
import {Helmet} from "react-helmet";

class Lcg extends Component {
  render() {
    return (
      <div>
              <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>Guidelines of Local SESI Chapters - Solar Energy Society of India</title>
<meta name="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="Guidelines of Local SESI Chapters - Solar Energy Society of India"/>
<meta itemprop="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Guidelines of Local SESI Chapters - Solar Energy Society of India"/>
<meta property="og:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Guidelines of Local SESI Chapters - Solar Energy Society of India"/>
<meta name="twitter:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>
        {/* Navigation bar */}
        <NavBar />
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Local Chapter Guidelines</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Local Chapter Guidelines</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  Lcg page content ====================*/}
        {/* #00a3ff */}
        <div className="text-center">
          <h2 className="m-6">
            {" "}
            Regional Chapter, State Chapter & Local Chapter{" "}
            <span className="text-[#00a3ff]"> Guidelines</span>
          </h2>
          
        </div>

        <div className="container">
          <p className=" text-center">
            The aim of the Government to reduce the use of carbon footprint and
            make awareness of the Green Energy specially in terms of Solar
            Energy in India. The Governing Council of Solar Energy Society of
            India [Indian section of International Solar Energy Society] wishes
            to establish the Regional Chapter, State Chapter & Local Chapter in
            different cities of the country at the premises of Central/ State
            Government Organisation, University, Academic Institution, Corporate
            House and Industries providing the infrastructural supports.
          </p>
        </div>
<SesiGuidelines/>
        <div className="container">
          <p className="text-lg underline font-bold">
            Contact Us:
          </p>
          <p className="text-lg">
           <b> Secretary General </b> <br />
           
                          New Building - <br />
                         Indian Society for Technical Education (ISTE), <br />
                          Shaheed Jeet Singh Marg, <br/> New Delhi - 110016 
          </p>
          <p className="text-lg">
            WhatsApp No.: <a href="http://wa.me/+919355155776">9355155776</a> 
          </p>
          <p className="text-lg">
            Email id: <a href="mailto:sg@sesi.in"> sg@sesi.in</a>
          </p>
        </div>
        {/* <div className='text-center'>
        <a download
            href="./assets/pdf/Form_SESI.pdf"
            className="m-4 text-white font-semibold text-xl py-3 px-5 rounded-l bg-[#00a3ff] hover:bg-[#00a3df]"
          >
            Download Form{" "}
          </a>
         </div> */}

        {/*====================  End of Lcg page content  ====================*/}
<br />
        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Lcg;
