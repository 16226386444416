/* eslint-disable */
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";

import FormUI from '../components/formsUI/FormUI'

// import { loader } from "../assets/scss/loader.svg";

const InnovationForm = () => {
    const navigate = useNavigate();
    const [innovations, setInnovations] = useState({});
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const formChangeHandler = (e) => {
        e.preventDefault();
        setInnovations({ ...innovations, [e.target.name]: e.target.value });
        // console.log(e.target.name," : ",e.target.value);   
    }

    const formSubmitHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        console.log(innovations);
        Email.send({
            Host: "smtp.elasticemail.com",
            Username: "tyagidivyam21@gmail.com",
            Password: "B2B68522D45466ABE6108F6BB3FDDD6AA4F7",
            Port: 2525,
            To: innovations.email,
            From: "tech.sesi@gmail.com",
            Subject: "SESI Innovations",
            Body: `
                Hello ${innovations.name}, you have successfully submitted the details of your innovations.
                Following are the details :::  ${JSON.stringify(innovations)}
            `,
        }).then(
            (message) => {
                if (message === "OK") {
                    alert("An Email has been sent. Check your mail(Spam Folder)");
                }
                else {
                    alert("Error while sending mail try again")
                }
                emailjsHandler();

            }
        );
    }

    const emailjsHandler = () => {
        setLoading(true);
        console.log(":Inside");

        emailjs.sendForm('service_ah0l5x1', 'template_i6172mz', form.current, 'okvDyQxFr7Hsgbysx')
            .then((result) => {
                console.log(result.text);
                setLoading(false);
                setLoading(false);
                setInnovations({});
                navigate("/");
            }, (error) => {
                console.log(error.text);
            });

    }
    /* eslint-disable */

    return (
        <FormUI title="Share Innovation Form">
            <form ref={form}>

                <div className="mb-2">
                    <label
                        htmlFor="name"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Name:
                    </label>
                    <input
                        required
                        type="text"
                        onChange={formChangeHandler}
                        name='name'
                        className="block w-full px-4 py-2 mt-2 text-blue-400 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mb-2">
                    <label
                        htmlFor="address"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Address:
                    </label>
                    <input
                        required
                        type="text"
                        onChange={formChangeHandler}
                        name='address'
                        className="block w-full px-4 py-2 mt-2 text-blue-400 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mb-2">
                    <label
                        htmlFor="telephone"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Telephone:Proceed
                    </label>
                    <input
                        type="text"
                        onChange={formChangeHandler}
                        name='telephone'
                        className="block w-full px-4 py-2 mt-2 text-blue-400 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mb-2">
                    <label
                        htmlFor="mobileNum"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Mobile Number:
                    </label>
                    <input
                        required
                        type="number"
                        onChange={formChangeHandler}
                        name='mobileNum'
                        className="block w-full px-4 py-2 mt-2 text-blue-400 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mb-2">
                    <label
                        htmlFor="email"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Email ID:
                    </label>
                    <input
                        required
                        type="email"
                        onChange={formChangeHandler}
                        name='email'
                        className="block w-full px-4 py-2 mt-2 text-blue-400 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mb-2">
                    <label
                        htmlFor="innovations"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Write down your Innovations:
                    </label>
                    <textarea
                        required
                        type="text"
                        onChange={formChangeHandler}
                        name='innovations'
                        className="block w-full px-4 py-2 mt-2 text-blue-400 bg-white border rounded-md focus:border-indigo-400 focus:ring-indigo-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div onClick={formSubmitHandler} className="cursor-pointer w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-indigo-600">
                    {!loading ? "Submit" : "loading..."}
                </div>
            </form>
        </FormUI>

    )
}

export default InnovationForm