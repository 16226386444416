import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const Gallery = () => {
    const [index, setIndex] = useState(-1);
    const PROJECT_IMAGES = [
       
        "assets/img/gallery/14.png",
        "assets/img/gallery/13.png",
        "assets/img/gallery/12.png",
        "assets/img/gallery/15.png",
        "assets/img/gallery/10.png",
        "assets/img/gallery/9.png",
        "assets/img/gallery/5.jpeg",
        "assets/img/gallery/6.jpeg",
        "assets/img/gallery/1.jpeg",
        "assets/img/gallery/2.jpeg",
        "assets/img/gallery/3.jpeg",
        "assets/img/gallery/4.jpeg",
    ];
    const slides = PROJECT_IMAGES.map((img, i) => ({
        src: img,
        key: i,
    }));
    return (
        <div>
            <div className="row row-5 ">
                {PROJECT_IMAGES.map((image, idx) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10" key={idx}>
                        <button className="gallery-item single-gallery-thumb" onClick={() => setIndex(idx)}>
                            <img src={image} className="img-fluid sm:px-4 px-6" alt="" /><span className="plus" />
                        </button>
                    </div>
                ))}
                <Lightbox
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    slides={slides}
                    plugins={[Thumbnails, Zoom, Fullscreen]}
                />
            </div>
        </div>
    );
}

export default Gallery;


