import React, { Component } from 'react';

class BlogPostContent extends Component{
    render(){
            
        /* post tag */
        
        let tagData = [
            {tagLink: "blog-left-sidebar", tagName: "Renovation"},
            {tagLink: "blog-left-sidebar", tagName: "Architecture"},
            {tagLink: "blog-left-sidebar", tagName: "Concrete"}
        ];

        let tagDataList = tagData.map((val, i)=>{
            return(
                <li key={i}><a href={`${process.env.PUBLIC_URL}/${val.tagLink}`}>{val.tagName}</a></li>
            )
        });

        return(
            <div>
                <div className="row">
                    <div className="blog-details col-12">
                    <div className="blog-inner">
                        <div className="media"><div className="image"><img src="assets/img/blog/1.png" alt="solar-world-congress" /></div></div>
                        <div className="content">
                        <ul className="meta">
                            <li>By <a href="blog-left-sidebar">SESI</a></li>
                            <li>27 July 2023</li>
                           
                        </ul>
                        <h1 className="title">Solar World Congress 2023: <br /> Illuminating the Future of Solar Energy</h1>
                        <div className="desc section-space--bottom--30 text-justify">
                            <p className='text-l'>The world is steadily moving towards a greener, cleaner future, and the Solar World Congress 2023 (SWC2023) plays a pivotal role in this journey. The congress is slated to be a grand gathering of scientists, engineers, and technocrats, all passionate about harnessing the power of the sun.</p>
                            <br />
                            <blockquote className="blockquote section-space--bottom--30 section-space--top--30">
                            <h3>Unraveling the Details</h3>
                            <br />
                            <p>Scheduled to take place in 2023, SWC2023 is the go-to event for everyone interested in the future of solar energy. Its official website <a href="https://www.swc2023.org/"> SWC - 2023</a> is the hub for all event-related information.</p>
                            {/* <span className="author">__Denise Miller</span> */}
                            <ol className='ml-8' style={{ listStyleType: 'disc'}}>
                                <li><b>Conference Theme: </b>The primary agenda is to deepen our understanding of sustainable solar technologies and their potential impact on our planet.</li>
                                <li><b>Participants:</b>The event will see participation from renowned scientists, researchers, engineers, and policy-makers from around the globe, all coming together to discuss the future of solar energy.</li>
                                <li><b> Sessions and Workshops:</b>The congress will feature keynotes, panel discussions, and workshops on various aspects of solar energy, from photovoltaics to thermal systems, and from policy matters to technical breakthroughs.
</li>            
                            </ol>
                            </blockquote>
                            <br />
                            <h3>Why You Should Attend?</h3>
                            <br />
                            <p >The SWC2023 is set to be a melting pot of knowledge and innovation. Here's why you shouldn't miss it:</p>
                            <ol className='ml-8' style={{ listStyleType: 'disc'}}>
                                <li><b>Knowledge Sharing: </b>Learn from the experts about latest advancements, future trends, and challenges in solar technology.</li>
                                <li>
                                    <b>Networking Opportunities: </b>
                                    Connect with like-minded individuals and organisations, opening doors to future collaborations.
                                </li>
                                <li>
                                    <b> Innovative Solutions: </b>
                                    Explore innovative solutions that have the potential to revolutionise the renewable energy landscape.
                                </li>
                            </ol>
                            <br />
                            <h3>
                            Light at the End of the Tunnel: The Future of Solar Energy
                            </h3>
                            <br /> 
                            <p>As we grow more conscious about our environmental responsibilities, solar energy emerges as a beacon of hope. Its potential to provide clean, sustainable energy is unrivaled, and events like SWC2023 are instrumental in bringing this potential to light.
                            </p>
                            <br />
                            <h3>
                            Conclusion
                            </h3>
                            <br />
                            <p>The Solar World Congress 2023 promises to be a platform that illuminates the path to a sustainable future powered by solar energy. From technology enthusiasts to environmental activists, it is a must-attend event for all. Mark the dates in your calendar and gear up to delve into a world of sustainable innovation at SWC2023!</p>
                        </div>
                        {/* <ul className="tags">
                            <li><i className="fa fa-tags" /></li>
                            {tagDataList}
                        </ul> */}
                        </div>
                    </div>
                    </div>
                    <div className="col-12 section-space--top--60">
                        {/* Comments Below */}
                    {/* <div className="comment-wrapper">
                        <h3>Leave Your Comment</h3>
                        <div className="comment-form">
                        <form action="#">
                            <div className="row row-10">
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="text" placeholder="Your Name" /></div>
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="email" placeholder="Your Email" /></div>
                            <div className="col-12"><textarea placeholder="Your Message" defaultValue={""} /></div>
                            <div className="col-12"><input type="submit" defaultValue="Send Comment" /></div>
                            </div>
                        </form>
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogPostContent;