import React, { Component } from 'react';

class TeamMemberGrid extends Component {
    render() {


        /* team member data */

        let data = [
            {
                profileImage: "17.png",
                profileTitle: "Mr. Mohd. Yunus \n Khan",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "18.png",
                profileTitle: "Mr. Virendra Singh Verma",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "2.png",
                profileTitle: "Mr. G. Sivaramakrishnan",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "14.png",
                profileTitle: "Mr. Yogesh Y \n Pundlik",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "10.png",
                profileTitle: "Mr. Bingi Kedar Rao",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "8.png",
                profileTitle: "Mr. Inder Singh",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "15.png",
                profileTitle: "Dr. Ritesh Dash",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "20.png",
                profileTitle: "Mrs. Kumud Tyagi",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "3.png",
                profileTitle: "Dr. Y Anand",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },
            {
                profileImage: "16.png",
                profileTitle: "Dr. Sanjeev Anand",
                profileDesignation: "Member",
                profileEmail: "",
                socialLinks: {
                    facebook: "//www.facebook.com",
                    linkedin: "//www.linkedin.com",
                },
            },

        ];

        let Datalist = data.map((val, i) => {
            return (
                <div className="col-lg-3 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="team bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500">
                        <div className="image">
                            <img src={`assets/img/team/${val.profileImage}`} alt={val.profileTitle} />
                        </div>
                        <div className="content">
                            <h3 style={{ whiteSpace: "pre-line" }} className="title">{val.profileTitle}</h3>
                            <span className='text-blue-500 font-extrabold'>{val.profileDesignation}</span>
                            <a href={'mailto:' + val.profileEmail} className="email">{val.profileEmail}</a>
                            <div className="social">
                                <a href={val.socialLinks.facebook} className="facebook"><i className="fa fa-facebook" /></a>
                                {/* <a href={val.socialLinks.twitter} className="twitter"><i className="fa fa-twitter" /></a> */}
                                <a href={val.socialLinks.linkedin} className="linkedin"><i className="fa fa-linkedin" /></a>
                                {/* <a href={val.socialLinks.googlePlus} className="google"><i className="fa fa-google-plus" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <div>
                {/*====================  team member area ====================*/}
                <div className="team-member-area section-space--inner--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title section-space--bottom--50">Members of Governing Council </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex ">
                            <div className="col-lg-12">
                                <div className="team-member-wrapper">
                                    <div className="row justify-content-center">
                                        {/* team member list */}
                                        {Datalist}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of team member area  ====================*/}

            </div>
        )
    }
}

export default TeamMemberGrid;