import React, { Component, useState } from "react";

class StudentBlocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      imagesPerPage: 8,
    };
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
    // Scroll to the top of the page when a page number is clicked
    window.scrollTo(0, 0);
  };
  render() {
    /* team member data */

    let data = [
      {
        logo: "1.png",
        college: "Institute of Engg & Management",
        state: "West Bengal",
        num: "SC/2019/01",
        mail: "arun.bar@iemcal.com",
        website: "https://iem.edu.in/",
      },

      {
        logo: "2.png",
        college: "Guru Nanak Institute of Technology",
        state: "West Bengal",
        num: "SC/2019/02",
        mail: "principal_gnit@jisgroup.org",
        website: "https://gnit.ac.in/",
      },

      {
        logo: "3.png",
        college: "Kunja Bihari \n College",
        state: "Odisha",
        num: "SC/2019/03",
        mail: "naimuddin1969@gmail.com",
        website: "https://www.kbcollegebarang.org/",
      },

      {
        logo: "4.png",
        college: "Netaji Subhas Memorial City College",
        state: "Odisha",
        num: "SC/2019/04",
        mail: "nsmcity@gmail.com",
        website: "https://www.nsmcity.ac.in/",
      },

      {
        logo: "5.png",
        college: "Gandhi Institute for Technology",
        state: "Odisha",
        num: "SC/2019/05",
        mail: "amar.das@gift.edu.in",
        website: "https://autonomous.gift.edu.in/",
      },

      {
        logo: "6.png",
        college: "REVA University\n" + "\n",
        state: "Karnataka",
        num: "SC/2019/06",
        mail: "varunkumarreddy.n@reva.edu.in",
        website: "https://www.reva.edu.in/",
      },

      {
        logo: "7.png",
        college: "GIET University\n" + "\n",
        state: "Odisha",
        num: "SC/2019/07",
        mail: "rakeshsahu@giet.edu",
        website: " https://www.giet.edu/",
      },

      {
        logo: "8.png",
        college: "N.S.M City \n College",
        state: "Odisha",
        num: "SC/2020/08",
        mail: "spm8463@gmail.com",
        website: "https://www.nsmcity.ac.in/",
      },

      {
        logo: "9.png",
        college: "Axis Institute of Technology and Management ",
        state: "Uttar Pradesh",
        num: "SC/2020/09",
        mail: "directoraitm@axiscolleges.in",
        website: "https://axiscolleges.org/axis-institute-of-technology-management/",
      },

      {
        logo: "10.png",
        college: "SRM Institute of Science and Technology",
        state: "Tamil Nadu",
        num: "SC/2020/10",
        mail: "hod.ece.ktr@srmist.edu.in",
        website: "https://www.srmist.edu.in/",
      },

      {
        logo: "11.png",
        college: "SSBT College of Engineering & Technology",
        state: "Maharashtra",
        num: "SC/2020/11",
        mail: "devendra_sadaphale@rediffmail.com",
        website: "https://www.sscoetjalgaon.ac.in/",
      },

      {
        logo: "12.png",
        college: "Prasad V Potluri Siddhartha Institute of Technology",
        state: "Andhra Pradesh",
        num: "SC/2020/12",
        mail: "bhavana.kadiyala@pvpsiddhartha.ac.in",
        website: " https://www.pvpsiddhartha.ac.in/",
      },

      {
        logo: "13.png",
        college: "All India Shri Shivaji Memorial Societies College of Engineering",
        state: "Maharashtra",
        num: "SC/2021/13",
        mail: "msdeshmukh@aissmscoe.com, npmawale@aissmscoe.com",
        website: "https://aissmscoe.com/",
      },

      {
        logo: "14.png",
        college: "Malla Reddy Engineering College\n" + "\n",
        state: "Telangana",
        num: "SC/2022/14",
        mail: "eeehod@mrec.ac.in",
        website: "https://www.mrec.ac.in/",
      },

      {
        logo: "15.png",
        college: "Sagar Institute of Science Technology & Engineering",
        state: "Madhya Pradesh",
        num: "SC/2022/15",
        mail: "pankajsharma@sistec.ac.in",
        website: "https://www.sistec.ac.in/",
      },

      {
        logo: "16.png",
        college: "Muthayammal Engineering College\n" + "\n",
        state: "Tamil Nadu",
        num: "SC/2022/16",
        mail: "suresh.r.eee@mec.edu.in",
        website: "https://mec.edu.in/",
      },

      {
        logo: "17.png",
        college: "Kumaraguru \n College of Technology\n" + "\n",
        state: "Tamil Nadu",
        num: "SC/2022/17",
        mail: "vinothkumar.n.eee@kct.ac.in",
        website: "https://www.kct.ac.in/",
      },

      {
        logo: "18.png",
        college: "Balasore College of Engineering & Technology",
        state: "Odisha",
        num: "SC/2022/18",
        mail: "principal@bcetodisha.ac.in",
        website: "https://bcetodisha.ac.in/",
      },

      {
        logo: "19.png",
        college: "Vellore Institute of Technology\n" + "\n",
        state: "Tamil Nadu",
        num: "SC/2022/19",
        mail: "sitharthan.r@vit.ac.in",
        website: "https://vit.ac.in/",
      },

      {
        logo: "20.png",
        college: "Maulana Abul Kalam Azad University of Technology",
        state: "West Bengal",
        num: "SC/2022/20",
        mail: "ananta_das59@yahoo.co.in",
        website: "https://makautwb.ac.in/",
      },

      {
        logo: "22.png",
        college: "Maharishi University of Information Tech.",
        state: "Uttar Pradesh",
        num: "SC/2023/21",
        mail: "helpdesk@muit.in",
        website: "https://www.maharishiuniversity.ac.in/",
      },

      {
        logo: "23.png",
        college: "Mahatma Gandhi Institute of Technology",
        state: "Telangana",
        num: "SC/2023/22",
        mail: "ao@mgit.ac.in",
        website: "https://mgit.ac.in/",
      },
      {
        logo: "24.png",
        college: "NMAM INSTITUTE OF TECHNOLOGY",
        state: "Maharashtra",
        num: "-",
        mail: "aneeshjose@nitte.edu.in",
        website: "https://nmamit.nitte.edu.in/",
      },
      {
        logo: "25.png",
        college: "Tulsiramji Gaikwad-Patil College of Engineering & Technology",
        state: "Maharashtra",
        num: "SC/2023/23",
        mail: "hod.electrical@tgpcet.com",
        website: "https://www.tgpcet.com/",
      },



    ];


    // Pagination logic
    const { currentPage, imagesPerPage } = this.state;
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = data.slice(indexOfFirstImage, indexOfLastImage);

    let Datalist = currentImages.map((val, i) => {
      return (

        <div
          className="col-lg-3 col-sm-6 col-12 section-space--bottom--30  px-6"
        >
          <div className="team bg-white  rounded-3xl shadow-xl hover:shadow-2xl hover:ring-4 shadow-blue-500/40 ring-2 ring-blue-500 hover:ring-blue-600">
            <a target="_blank" href={val.website}>
              <img className="mx-auto pt-4 w-3/4" src={`assets/img/Student-chapter/${val.logo}`} alt={val.college} />
            </a>
            <div className="content">
              <h3 style={{ whiteSpace: "pre-line" }} className="text-red">{val.college}</h3>
              <h4 className='text-blue-500 pt-4 font-extrabold'>{val.state}</h4>
              <span className="pt-4 font-extrabold">Chapter Code: &nbsp;{val.num}</span>
              <div className="social">
                <a href={'mailto:' + val.mail} className="">
                  <i className="zmdi zmdi-email" />
                </a>
              </div>

            </div>
          </div>

        </div>
      );
    });

    // Page numbers for pagination
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / imagesPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? "active" : ""}
          onClick={this.handleClick}
        >
          {number}
        </li>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area">
          <div className="container">
            <div className="row d-flex ">
              <div className="col-lg-12">
                <div className="team-member-wrapper">
                  <div className="row justify-content-center">
                    {/* team member list */}
                    {Datalist}
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-container">
              <ul className="pagination">{renderPageNumbers}</ul>
            </div>
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default StudentBlocks;
