import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
class SesiGuidelines extends Component {
  render() {
    /* service tab menu */
    let serviceTabMenuData = [
      { tabMenuName: "Regional Chapter" },
      { tabMenuName: "State Chapter" },
      { tabMenuName: "Local Chapter" },
      // {iconName: 'flaticon-010-tank-1', tabMenuName: 'Power and Energy'}
    ];

    let serviceTabMenuDatalist = serviceTabMenuData.map((val, i) => {
      return (
        <Tab key={i}>
          {" "}
          {/* <span className="icon">
            <i className={val.iconName} />
          </span>{" "} */}
          <span className="text">{val.tabMenuName}</span>
        </Tab>
      );
    });



    return (
      <div>
        {/*====================  service tab area ====================*/}
        <div className="service-tab-area section-space--inner--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* service tab wrapper */}

                <div className="service-tab-wrapper">
                  <Tabs>
                    <div className="row no-gutters">
                      <div className="col-md-4">

                        <TabList>
                          <div className="service-tab__link-wrapper">
                            {serviceTabMenuDatalist}
                          </div>
                        </TabList>
                      </div>

                      <div className="col-md-8">
                        <TabPanel>
                        <div className="">
    <div className="w-full p-6 bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500 lg:max-w-xl">

                              <h3 className="service-tab__title text-black text-center">Regional Chapter</h3>
                              <p className="service-tab__text">
                                <ul >
                                    <li style={{ listStyleType: 'disc'}}>Five (5) Regional Chapter can be established in the country as per jurisdiction; namely
                                        <ul className="ml-8" style={{ listStyleType: 'circle'}}>
                                            <li>Northern Regional Chapter</li>
                                            <li>Southern Regional Chapter</li>
                                            <li>Eastern Regional Chapter</li>
                                            <li>Western Regional Chapter</li>
                                            <li>Central Regional Chapter</li>
                                        </ul>
                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Industries, Corporate House and Academic Institution may apply
                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Strength of the Life Member SESI on the date of application for approval should be 100.
                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    On approval from the Governing Council [SESI], The chairman and Honorary Secretary may be elected of the respective Regional Chapter
                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Regular activities may be organised as approved in the GC, SESI time to time
                                    </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                        <div className="">
    <div className="w-full p-6 bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500 lg:max-w-xl">
                              <h3 className="service-tab__title text-black text-center">State Chapter</h3>
                              <p className="service-tab__text">
                              <ul >
                                    <li style={{ listStyleType: 'disc'}}>
                                    The state chapter can be established in the capital of the concern state across the country.                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Industries, Corporate House and Academic Institution may apply	                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Strength of Life Member SESI on the date of application for approval should be 50.                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    On approval from the Governing Council [SESI], The chairman and Honorary Secretary may be elected of the respective State Chapter                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Regular activities may be organised as approved in the GC, SESI time to time.                                    </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </TabPanel>
                        
                        <TabPanel>
                        <div className="">
    <div className="w-full p-6 bg-white rounded-md shadow-xl shadow-blue-500/40 ring-2 ring-blue-500 lg:max-w-xl">
                              <h3 className="service-tab__title text-black text-center">Local Chapter</h3>
                              <p className="service-tab__text">
                              <ul >
                                    <li style={{ listStyleType: 'disc'}}>
                                    Local chapter can be established across all the cities of the country.                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Industries, Corporate House and Academic Institution may apply	                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Strength of Life Member SESI on the date of application for approval should be 25.                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    On approval from the Governing Council [SESI], The chairman and Honorary Secretary may be elected of the respective State Chapter                                    </li>
                                    <li style={{ listStyleType: 'disc'}}>
                                    Regular activities may be organised as approved in the GC, SESI time to time.                                    </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of service tab area  ====================*/}
      </div>
    );
  }
}

export default SesiGuidelines;
