import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Regi from "./pages/Regi";
import Innovation from "./pages/Innovation";
import SesiExchange from "./service/SesiExchange";
import Membership from "./pages/Membership";
import SesiQuery from "./pages/Query";
import SGeneral from "./service/SGeneral";
import President from "./service/President";
import VPresident from "./service/VPresident";
import Login from "./components/formsUI/Login";
import RegForm1 from "./pages/Forms/RegForm1";
import RegForm2 from "./pages/Forms/RegForm2";
import Profile from "./pages/Profile";
import Scg from "./pages/Scg";
import Institutional from "./pages/Institutional";
import StudentChapter from "./pages/StudentChapter";
import LatestEvents from "./pages/LatestEvents";
import Lcg from "./pages/Lcg";
import RegionalChapter from "./pages/Regional-Chapter";
import Announcements from "./pages/Announcements";
import GalleryPage from "./pages/GalleryPage";
import HomeThree from "./home/HomeThree";
import { Helmet } from "react-helmet";
import Awards from "./pages/Awards";
import ForgPass1 from "./components/formsUI/ForgPass1";
import ForgPass2 from "./components/formsUI/ForgPass2";
import Career from "./pages/Career";
import RoleofSesi from "./blog/RoleofSesi";
import UserRegistrationForm from "./pages/Forms/regtest";
import getepayPortal from './components/Getepay_pg_react';

// const Announcements = lazy(() => import("./pages/Announcements"))
const HomeOne = lazy(() => import("./home/HomeOne"));
const About = lazy(() => import("./pages/About"));
const Chapter = lazy(() => import("./pages/Chapter"));
const CouncilMember = lazy(() => import("./service/CouncilMember"))
const ServiceDetailsLeftSidebar = lazy(() => import("./service/ServiceDetailsLeftSidebar"));
const ServiceDetailsRightSidebar = lazy(() => import("./service/ServiceDetailsRightSidebar"));
const News_Updates = lazy(() => import("./project/NewsUpdates"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));
const Blog = lazy(() => import("./blog/Blog"));
const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
const SolarWorldCongress = lazy(() => import("./blog/SolarWorldCongress"));
const BlogDetailsRightSidebar = lazy(() => import("./blog/BlogDetailsRightSidebar"));
const Contact = lazy(() => import("./pages/Contact"));
const NoMAtch = lazy(() => import("./pages/404"));

//  <script src="./assets/tailwind.js"></script> 


const App = () => {
  const [form1, setForm1] = useState(true);
  // const [member, setMember] = useState();

  const postPayClick = (member) => {
    localStorage.setItem('member', JSON.stringify(member));
    fetch('https://api.sesi.in/auth/signup', {
      method: "PUT",
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify(member)
    })
      .then((response) => {
        // console.log(member)
        const regFee = member.regFee;
        let amount;
        switch (regFee) {
          case "fellow":
            amount = 15000;
            break;
          case "institutional":
            amount = 25000;
            break;
          case "solar":
            amount = 6000;
            break;
          case "life":
            amount = 5000;
            break;
          case "annual":
            amount = 1000;
            break;
          case "studentEng":
            amount = 500;
            break;
          case "test":
            amount = 10;
            break;
          default:
            amount = 400;
        }
        const na = member.name;
        const mob = member.mobileNumber;
        const em = member.email;
        const date = new Date();
        // alert(mob);
        // alert(date);

        const data = {
          mid: "1018896",
          amount: amount,
          merchantTransactionId: "sd12121",
          transactionDate: date,
          terminalId: "getepay.idbibank374787@icici",
          udf1: mob,
          udf2: em,
          udf3: na,
          udf4: "4",
          udf5: "5",
          udf6: "6",
          udf7: "7",
          udf8: "8",
          udf9: "9",
          udf10: "10",
          ru: "https://sesi.in/login",
          callbackUrl: "https://sesi.in/login",
          currency: "INR",
          paymentMode: "ALL",
          bankId: "",
          txnType: "single",
          productType: "IPG",
          txnNote: "Test Txn",
          vpa: "Getepay.merchant61062@icici",
        };


        const Config = {
          "Getepay Terminal Id": "getepay.idbibank374787@icici",
          "Getepay Key": "V6naZllRXh8vT++rNWUX0vZQASQH3waeQffLUMS6AdM=",
          "Getepay IV": "ZGh6I8hgFke3GICTxji9xQ==",
          "Getepay Url":
            "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
        };
        getepayPortal(data, Config);

        // Email.send({
        //   Host: "smtp.elasticemail.com",
        //   Username: "tyagidivyam21@gmail.com",
        //   Password: "B2B68522D45466ABE6108F6BB3FDDD6AA4F7",
        //   Port: 2525,
        //   To: member.email,
        //   From: "tech.sesi@gmail.com",
        //   Subject: "SESI Membership Registration",
        //   Body: `
        //   Hello ${member.name}, you have successfully paid the amount and your application has been successfully submited.
        //   Below are the details : ${JSON.stringify(member)}
        // `
        // })
      })
      .then(
        (message) => {
          alert("Your account is created successfully. Kindly pay your fees")
          if (message === "OK") {
            alert("An Email has been sent. Check your mail(Spam Folder)");
          }
          // setLoading(false);
          // window.location.assign("https://sesi.in/login");
        }
      );
    setForm1(!form1);
  }


  return (
    <div>

      <Router>
        <Suspense fallback={<div />}>
          <Routes>
            {/* <Route path="/gg" element={<HomeThree />} /> */}
            <Route path="/careers" element={<Career />} />
            <Route path="/" element={<HomeOne />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/regional-chapter" element={<RegionalChapter />} />
            <Route path="/home" element={<HomeOne />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/chapters" element={<Chapter />} />
            <Route path="/institutional-members" element={<Institutional />} />
            <Route path="/student-chapters" element={<StudentChapter />} />
            <Route path="/member" element={<CouncilMember />} />
            <Route path="/secretary-treasurer" element={<SGeneral />} />
            <Route path="/president" element={<President />} />
            <Route path="/vice-president" element={<VPresident />} />
            <Route path="/service-details-left-sidebar" element={<ServiceDetailsLeftSidebar />} />
            <Route path="/service-details-right-sidebar" element={<ServiceDetailsRightSidebar />} />
            <Route path="/news-updates" element={<News_Updates />} />
            <Route path="/past-events" element={<ProjectDetails />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
            <Route path="/solar-world-congress" element={<SolarWorldCongress />} />
            <Route path="/role-of-sesi" element={<RoleofSesi />} />
            <Route path="/blog-details-right-sidebar" element={<BlogDetailsRightSidebar />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="*" element={<NoMAtch />} />
            <Route path="/innovation" element={<Innovation />} />
            <Route path="/sesi-exchange" element={<SesiExchange />} />
            <Route path="/sesi-query" element={<SesiQuery />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/student-chapter-guidelines" element={< Scg />} />
            <Route path="/local-chapter-guidelines" element={<Lcg />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/local-chapter-guidelines" element={<Lcg />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/latest-events" element={<LatestEvents />} />
            <Route path="/login" element={<Login />} />
            <Route path="/regtest" element={<UserRegistrationForm />} />
            <Route path="/register" element={<RegForm1 payClickHandler={postPayClick} />} />
            <Route path="/register/2" element={<RegForm2 />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/forgot-password" element={<ForgPass1 />} />
            <Route path="/reset-password/:userId" element={<ForgPass2 />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  )
}

export default App