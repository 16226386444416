import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import CareerBlocks from "../components/CareerBlocks";
import { Helmet } from "react-helmet";

class Career extends Component {
  render() {
    return (
      <div>
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>Careers with SESI - Solar Energy Society of India</title>
          <meta
            name="description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta
            itemprop="name"
            content="Careers with SESI - Solar Energy Society of India"
          />
          <meta
            itemprop="description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            itemprop="image"
            content="https://sesi.in/assets/img/logo.png"
          />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://sesi.in/" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Careers with SESI - Solar Energy Society of India"
          />
          <meta
            property="og:description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            property="og:image"
            content="https://sesi.in/assets/img/logo.png"
          />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Careers with SESI - Solar Energy Society of India"
          />
          <meta
            name="twitter:description"
            content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."
          />
          <meta
            name="twitter:image"
            content="https://sesi.in/assets/img/logo.png"
          />
        </Helmet>
        {/* Navigation bar */}
        <NavBar />

        {/*====================  Chapter page content ====================*/}

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>SESI Careers</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>SESI Careers</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}


        <CareerBlocks />

        <br />
 
  <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
    <img class="sm:w-1/2  mb-10 object-cover ring-4 object-center rounded" alt="hero" src="./assets/img/career.jpeg"/>
  </div>


        {/*====================  End of Chapter page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Career;
