import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className="footer-area bg-[#82D1FF] ">
          <div className="container  mx-auto">
            <div className="row ">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--100">
                  <div className="row  mx-auto">
                    
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      {/* footer intro wrapper */}
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo ">
                          <a href={`${process.env.PUBLIC_URL}/home-one`}>
                            {/* <img
                             
                              src="assets/img/logo/flogo.png"
                              className="img-fluid"
                              alt=""
                            /> */}
                            <h5 className="text-black">SESI India</h5>
                          </a>
                       
                        <div className="footer-desc">
                        <br/>
                        Solar Energy Society of India (SESI), established in 1978, having its Secretariat in New Delhi, 
                        is the Indian Section of the International Solar Energy Society (ISES)
                        </div>
                      
                </div>
                          {/* <img width={140} height={120} alt="Flag Counter"
src="https://s01.flagcounter.com/count2/EQIB/bg_FFFFFF/txt_000000/border_CCCCCC/columns_2/maxflags_10/viewers_0/labels_0/pageviews_0/flags_0/percent_0/"/> */}

                      </div>
            </div>
                    
                    <div className="col-xl-1 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title text-black">LINKS</h4>
                        <ul className="footer-widget__navigation ">
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`}>Home</a>
                          </li>
                          <li>
                            <a className="text-black" href={`${process.env.PUBLIC_URL}/about-us`}>
                              About Us
                            </a>
                          </li>
                          <li>

                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/member`}>Members </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/past-events`}>Events</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/contact-us`}>
                              Contact Us
                            </a>
                          </li>
                          <li>
                          
        
                              
                         
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title">USEFUL LINKS</h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a target="_blank" href={`https://mnre.gov.in/`}>
                              MNRE
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href={`https://ieefa.org/`}>
                              IIEFA
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href={`https://sscgj.in/`}>
                              SSCGJ
                            </a>
                          </li>
                  
                          <li>
                            <a target="_blank" href={`https://www.ises.org/`}>ISES</a>
                          </li>
                          <li>
                            <a target="_blank" href={`https://www.seci.co.in/`}>SECI</a>
                          </li>
                          <li>
                            <a target="_blank" href={`https://isolaralliance.org/`}>
                              ISA
                            </a>
                          </li>
                          
                          
                        </ul>
                      </div>
                    </div>

                    <div className="col-xl-3 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget mb-0">
                      
                        <h4 className="footer-widget__title">  CONTACT US</h4>
                        <div className="footer-widget__content">
                        
                          <p className="address">
                         <b> Solar Energy Society of India</b> <br />
        
                          New Building -<br />
                         Indian Society for Technical Education (ISTE), <br />
                          Shaheed Jeet Singh Marg,<br /> New Delhi - 110016 
                          </p>
                          <ul className="contact-details">
                            <li>
                              P: +91 9355155776
                            </li>
                        
                            <li>
                              <span>E:</span>info@sesi.in
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              <p className="copyright-text">
              Copyright &copy; SESI {new Date().getFullYear()} Made By &nbsp;
                <a target="_blank" href="https://myctoinnovations.com/"> MyCTO Innovations</a>
              </p>
            </div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
        {/*====================  End of scroll top  ====================*/}
      </div>
    );
  }
}

export default Footer;
