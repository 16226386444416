import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import {Helmet} from "react-helmet";
class SesiQuery extends Component {
  render() {
    return (
      <div>
   
        {/* Navigation bar */}
        <NavBar />
        <Helmet>
      {/* <!-- HTML Meta Tags --> */}
<title>SESI Exchange Queries - Solar Energy Society of India</title>
<meta name="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>

{/* <!-- Google / Search Engine Tags --> */}
<meta itemprop="name" content="SESI Exchange Queries - Solar Energy Society of India"/>
<meta itemprop="description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta itemprop="image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="https://sesi.in/"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="SESI Exchange Queries - Solar Energy Society of India"/>
<meta property="og:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta property="og:image" content="https://sesi.in/assets/img/logo.png"/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="SESI Exchange Queries - Solar Energy Society of India"/>
<meta name="twitter:description" content="SESI (Solar Energy Society of India) is the leading organisation dedicated to promoting renewable energy in India. Join us to shape India's sustainable future."/>
<meta name="twitter:image" content="https://sesi.in/assets/img/logo.png"/>

      </Helmet>

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>SESI-Exchange Queries</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="Innovation">SESI-Exchange Queries</a>
                    </li>
                    \
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}

        <div className="service-grid-slider-area section-space--inner--120  text-center">
          <h2 className="section-title section-space--bottom--50">
          SESI-Exchange Queries
          </h2>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area text-center">
                  <img
                    src="assets/img/Innovation/project-details.jpg"
                    className=""
                    alt=""
                  />
                  {/* <p>
                    Solar energy is available in ample, abundance, free of cost
                    in nature, around us. Many of us have thoughts/ideas to put
                    it in to your/our own service. Many of you may come across
                    some innovative applications of solar energy. May be a small
                    one...here is a chance to upload this innovation of yours or
                    someone from INDIA so that small but innovative act can come
                    in front of many of our fellow citizens.
                  </p> */}
                  <iframe 
                          title="SESI EXCHANGE"
                          src="https://docs.google.com/forms/d/1NvjPiJL3jQCG1n29IANo6UEfeGbU7_5mhUsk11xldgM/viewform?embedded=true"
                          width="90%"
                          height="2261"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                  {/* <div className="row">
                    <div className="col">
                      <div className="page-banner text-center">
                        
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Project Information</h3>
                    <ul>
                      <li>
                        <strong>Client:</strong>{" "}
                        <a href="project-details">RRS Company</a>
                      </li>
                      <li>
                        <strong>Location:</strong> San Francisco
                      </li>
                      <li>
                        <strong>Area(sf):</strong> 550,000 sf
                      </li>
                      <li>
                        <strong>Year:</strong> 2019
                      </li>
                      <li>
                        <strong>Budget:</strong> $245000000
                      </li>
                      <li>
                        <strong>Architect:</strong> Scott &amp; Austin
                      </li>
                      <li>
                        <strong>Sector:</strong>{" "}
                        <a href="project-details">Tunnel</a>,{" "}
                        <a href="project-details">Transport</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Mountain Tunnel</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Voluptatem veritatis quo et ullam, ducimus itaque earum
                      dolorem? Consectetur, et, aut. A, corporis officia eius
                      dicta explicabo saepe nesciunt, mollitia minima, atque
                      maiores optio cum. Atque amet unde impedit voluptate
                      cumque distinctio minima, aspernatur nemo! Expedita in,
                      numquam blanditiis ullam rem!
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Modi cum fugit officia dolores eligendi, rem. Quibusdam
                      quasi impedit perspiciatis iure maiores, eaque numquam
                      doloremque, quo nam soluta itaque obcaecati tempore!.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Dolore ex, nam adipisci dolores laborum earum. Unde cum,
                      ut nostrum nihil alias, laudantium molestiae, vitae quidem
                      dolorem officiis ipsum. Aliquid nemo consequuntur
                      cupiditate delectus sapiente doloribus dolorem, at
                      suscipit, non laudantium mollitia magnam repellat atque
                      quia! Aut, veniam, nam. Ex porro optio facilis nostrum,
                      qui ipsa?
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <PhotoGallery />
                </div> */}
        {/* </div>
            </div>
          </div>
      
        </div> */}
        {/*Innovation section end*/}
        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        {/* <BrandLogoSlider background="grey-bg" /> */}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default SesiQuery;
