import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import NavBar from '../NavBar'


const corsProxyUrl = 'https://cors-anywhere.herokuapp.com/'
const ForgPass2 = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [pass, setPassword] = useState({
        password: ""
    });

    const passwordToggleHandler = () => {
        setShowPassword(!showPassword);
    }

    const handlePassword = (event) => {
        setPassword({ ...pass, ["password"]: event.target.value });


    }

    const fetchData = async () => {
        const fetchedData = await fetch(`https://api.sesi.in/user/profile/${userId}`)
        const res = await fetchedData.json()
        await setUser(res.user);
    }

    const resetClickHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        const fetchedData = await fetch(`https://api.sesi.in/user/resetPassword/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(pass)
        })
        setLoading(false);
        alert("Your password has changed successfully. Kindly login with your new password now");
        navigate('/login')
    }

    useEffect(() => {
        fetchData();
    }, [])



    return (
        <div>
            <NavBar />
            {user && <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <NavLink to="/" className="text-6xl text-center font-light text-indigo-900 flex justify-content-center">
                            <img src="assets/img/logo/logo.png" className="img-fluid" alt="Logo" />
                        </NavLink>
                        <h1 className="mt-6 text-center text-4xl font-extrabold text-black">Reset Password</h1>
                        <p className="mt-6 text-center text-xl text-gray-900">
                            Welcome
                            <span className='font-bold'> {user.name}</span>
                            , Enter your new password
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" action="#">
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    onChange={handlePassword}
                                    type={showPassword ? "text" : "password"}
                                    // autoComplete="password"
                                    required
                                    className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Password"
                                />
                            </div>

                        </div>

                        <div className="flex items-center">
                            <input
                                id="show"
                                name="show"
                                onChange={passwordToggleHandler}
                                type="checkbox"
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500
                  border-gray-300 rounded"

                            />
                            <label htmlFor="show" className="ml-2 block text-sm text-gray-900">
                                Show Password
                            </label>
                        </div>

                        <div>
                            <button
                                // type="submit"
                                onClick={resetClickHandler}
                                className="group relative w-full flex justify-center
                py-2 px-4 border border-transparent text-sm font-medium
                rounded-md text-white bg-blue-500 hover:bg-blue-600
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-indigo-500"
                            >
                                {loading ? "Wait..." : "Reset Password"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>}
            <Footer />
        </div>
    )
}

export default ForgPass2