import React, { Component } from 'react';

class MemberIcon extends Component {
    render() {

        let data = [
            { featureIcon: "1.png", featureTitle: "Fellow Member", featureDescription: "Distinguished solar energy professionals shaping a sustainable future." },
            { featureIcon: "2.png", featureTitle: "Institutional Member", featureDescription: " Organizations committed to advancing solar energy innovation and adoption." },
            { featureIcon: "3.png", featureTitle: "Annual Member", featureDescription: "Access to exclusive solar resources and networking opportunities yearly." },
            { featureIcon: "4.png", featureTitle: "Student Member", featureDescription: "Empowering the next generation of solar energy leaders and innovators." },
            { featureIcon: "5.png", featureTitle: "Life Member", featureDescription: "Lifelong support and engagement in the solar energy community." },
            { featureIcon: "6.png", featureTitle: "Solar Charter Engineer", featureDescription: "Accreditation for skilled engineers dedicated to solar energy excellence." },
        ];

        let Datalist = data.map((val, i) => {
            return (
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="feature">
                        <div className="icon">
                            <img src={`assets/img/icons/${val.featureIcon}`} className="img-fluid" alt={val.featureTitle} />
                        </div>
                        <div className="content">
                            <h3>{val.featureTitle}</h3>
                            <p>{val.featureDescription}</p>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div>
                {/*Feature section start*/}

                <div className={`feature-section  ${this.props.background}`}>
                    <h2 className='text-center className="text-bold" section-space--inner--100' >Memberships are available under the following categories:</h2>

                    <div className="container">
                        <div className="col-lg-12">
                            <div className="feature-item-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Feature section end*/}
            </div>
        )
    }
}

export default MemberIcon;